import { useWallet } from "solana-wallets-vue";
import { toast } from "vue3-toastify";
import { getQuote } from "@/utilities/jupiter/getQuote";
import { buildTransaction } from "@/utilities/jupiter/buildTransaction";
import { validateAmount } from "@/utilities/forms/validation";

// Function to initiate swap
export async function checkSwapStatus(swapQuote, amountValue, selectedToken) {
  const { publicKey, connected } = useWallet();
  //console.log("Checking inputs");
  if (connected.value && publicKey.value && swapQuote.value) {
    //console.log("Checking amount");
    //console.log("Amount:", amountValue);
    //console.log("selectedToken:", JSON.stringify(selectedToken));
    const isInvalidAmount = validateAmount(amountValue, selectedToken.balance);
    if (isInvalidAmount) {
      //console.log("Amount exceeds balance.");
      toast.warning("Amount exceeds available balance.", {
        autoClose: 5000,
        theme: "dark",
      });
      return false;
    }
    return true;
  } else {
    console.log(
      "Cannot send transaction. Make sure all required fields are filled and you are connected to the wallet."
    );
    toast.warning("Error, recheck form inputs.", {
      autoClose: 5000,
      theme: "dark",
    });
    return false;
  }
}

// Function to initiate swap
export async function performSwap(swapQuote, connection) {
  const { publicKey, sendTransaction } = useWallet();
  if (swapQuote.value && publicKey.value) {
    try {
      const transaction = await buildTransaction(
        swapQuote.value,
        publicKey.value
      );

      //console.log(`Sending tx...`);
      const txid = await sendTransaction(transaction, connection, {
        skipPreflight: false,
        preflightCommitment: "confirmed",
      });

      console.log(`Transaction sent. TxID: ${txid}`);
      return txid;
    } catch (error) {
      console.error("Error performing swap:", error);
    }
  }
}

export async function performSwapWithSimulation(
  swapQuote,
  selectedToken1,
  selectedToken2,
  initialAmount,
  connection
) {
  const { publicKey, sendTransaction } = useWallet();
  let simulationSuccess = false;
  let newAmount = initialAmount;

  let maxRetries = 6;
  let attempts = 0;
  let transaction;

  while (!simulationSuccess && publicKey.value && attempts < maxRetries) {
    try {
      if (!swapQuote) {
        // Fetch a new quote
        swapQuote = await getQuote(
          parseFloat(newAmount),
          selectedToken1,
          selectedToken2
        );
      }
      // Build the transaction based on the new quote
      transaction = await buildTransaction(swapQuote, publicKey.value);
      // Simulate the transaction
      const simulatedResult = await connection.simulateTransaction(
        transaction,
        {
          commitment: "confirmed",
          replaceRecentBlockhash: true,
        }
      );
      if (simulatedResult && simulatedResult.value.err === null) {
        simulationSuccess = true;
        console.log("Simulation succeeded, prompting user for signature...");
      } else {
        console.log("Simulation failed, fetching a new quote and retrying...");
        swapQuote = null;
        attempts++;
        await new Promise((r) => setTimeout(r, 2000));
      }
    } catch (error) {
      console.error("Error during simulation or fetching a new quote:", error);
      swapQuote = null;
      attempts++;
      await new Promise((r) => setTimeout(r, 2000));
      break;
    }
  }
  // Once simulation succeeds, send the transaction
  if (simulationSuccess && swapQuote) {
    try {
      const txid = await sendTransaction(transaction, connection, {
        skipPreflight: true,
        preflightCommitment: "confirmed",
      });
      console.log(`Transaction sent. TxID: ${txid}`);
      console.log(
        `View on explorer: https://xray.helius.xyz/tx/${txid}?network=mainnet`
      );
      return txid;
    } catch (error) {
      console.error("Error performing swap:", error);
      return null;
    }
  }
}
