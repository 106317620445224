import { defineStore } from "pinia";
import axios from "axios";

const API_BASE_URL =
  process.env.VUE_APP_API_BASE_URL || "http://localhost:8080"; // Adjust this as needed

export const useBookmarkStore = defineStore("bookmark", {
  state: () => ({
    bookmarks: [],
  }),
  actions: {
    async getUserBookmarks() {
      try {
        const response = await axios.get(`${API_BASE_URL}/users/bookmarks`, {
          withCredentials: true,
        });
        this.bookmarks = response.data;
      } catch (error) {
        console.error("Error fetching bookmarks:", error);
        // Don't throw the error, just log it
      }
    },
    async addBookmark(blogId) {
      try {
        await axios.post(
          `${API_BASE_URL}/users/bookmarks`,
          { blogId },
          { withCredentials: true }
        );
        if (!this.bookmarks.includes(blogId)) {
          this.bookmarks.push(blogId);
        }
      } catch (error) {
        console.error("Error adding bookmark:", error);
        // Don't throw the error, just log it
      }
    },
    async removeBookmark(blogId) {
      try {
        await axios.delete(`${API_BASE_URL}/users/bookmarks/${blogId}`, {
          withCredentials: true,
        });
        this.bookmarks = this.bookmarks.filter((id) => id !== blogId);
      } catch (error) {
        console.error("Error removing bookmark:", error);
        // Don't throw the error, just log it
      }
    },
  },
  getters: {
    isBookmarked: (state) => (blogId) => state.bookmarks.includes(blogId),
  },
});
