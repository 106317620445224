<template>
  <div
    class="fixed inset-0 bg-base-300 bg-opacity-80 overflow-y-auto h-full w-full text-base-content"
    id="my-modal"
    @click.self="closeModal"
  >
    <div
      class="relative top-40 mx-auto p-5 border w-96 shadow-lg rounded-xl bg-base-200"
    >
      <div class="mt-6 text-left">
        <h2
          class="text-2xl leading-6 font-medium text-base-content text-center mt-6 mb-6"
        >
          {{ modalTitle }}
        </h2>
        <div class="mt-2 px-4 py-3">
          <!-- Initial choice for users with existing alerts -->
          <div v-if="hasExistingAlerts && !choiceMade">
            <p class="text-base-content mb-4">
              You already have alerts for this project. What would you like to
              do?
            </p>

            <button
              @click="handleChoice('add')"
              class="mt-4 w-full px-4 py-2 mb-2 text-base-content rounded bg-primary hover:bg-primary-focus transition duration-300"
            >
              Add Another Alert
            </button>
            <button
              @click="handleChoice('remove')"
              class="w-full px-4 py-2 text-base-content rounded bg-neutral-focus hover:bg-base-100 transition duration-300 mb-2"
            >
              Remove Alert
            </button>
          </div>

          <!-- Add Alert Form -->
          <div v-if="!isRemoving && (choiceMade || !hasExistingAlerts)">
            <div class="mb-4">
              <label
                class="block text-base-content text-sm font-bold mb-2"
                for="alertType"
              >
                Alert Type
              </label>
              <select
                v-model="alertType"
                id="alertType"
                class="w-full p-2 border border-neutral bg-base-100 rounded text-gray-300"
              >
                <option value="priceTarget">Price Target</option>
                <option value="priceMovement">Price Movement</option>
              </select>
            </div>
            <div class="mb-4">
              <label
                class="block text-base-content text-sm font-bold mb-2"
                for="priceInput"
              >
                {{
                  alertType === "priceTarget"
                    ? "Price Target"
                    : "Price Movement (%)"
                }}
              </label>
              <input
                v-model="priceInput"
                type="number"
                id="priceInput"
                class="w-full p-2 border border-neutral bg-base-100 rounded"
                step="0.01"
              />
            </div>
            <div class="mb-4">
              <label
                class="block text-base-content text-sm font-bold mb-2"
                for="frequency"
              >
                Frequency
              </label>
              <select
                v-model="frequency"
                id="frequency"
                class="w-full p-2 border border-neutral bg-base-100 rounded"
              >
                <option value="oneTime">One time</option>
                <option value="everyTime">Every time</option>
              </select>
            </div>
            <div class="mb-4">
              <label
                class="block text-base-content text-sm font-bold mb-2"
                for="email"
              >
                Email (Optional)
              </label>
              <input
                v-model="email"
                type="email"
                id="email"
                class="w-full p-2 border border-neutral bg-base-100 rounded"
              />
            </div>
            <div class="mb-4">
              <label
                class="block text-base-content text-sm font-bold mb-2"
                for="discordWebhook"
              >
                Discord Webhook URL (Optional)
              </label>
              <input
                v-model="discordWebhook"
                type="url"
                id="discordWebhook"
                class="w-full p-2 border border-neutral bg-base-100 rounded"
              />
            </div>
          </div>

          <!-- Remove Alert Selection -->
          <div v-if="isRemoving && (choiceMade || !hasExistingAlerts)">
            <p class="text-sm text-base-content mb-4">
              Select the alert you want to remove:
            </p>
            <div
              v-for="alert in projectAlerts"
              :key="alert.alertId"
              class="mb-2"
            >
              <label class="flex items-center">
                <input
                  type="radio"
                  v-model="selectedAlertId"
                  :value="alert.alertId"
                  class="mr-2"
                />
                <span>{{ formatAlertDetails(alert) }}</span>
              </label>
            </div>
          </div>
        </div>
        <div class="items-center px-4 py-3">
          <button
            v-if="!isRemoving && (choiceMade || !hasExistingAlerts)"
            @click="submitAlert"
            class="w-full px-4 py-2 text-base-content rounded transition duration-300"
            :class="buttonClass"
          >
            {{ buttonText }}
          </button>
          <p v-if="showErrorMessage" class="text-red-500 text-sm mt-2">
            Please enter either an email or a Discord webhook URL.
          </p>
          <div v-if="isRemoving && (choiceMade || !hasExistingAlerts)" class="">
            <button
              @click="confirmRemove"
              class="w-full px-4 py-2 mb-2 text-base-content rounded bg-primary hover:bg-primary-focus transition duration-300"
              :disabled="!selectedAlertId"
            >
              Confirm
            </button>
            <button
              @click="closeModal"
              class="w-full px-4 py-2 text-base-content rounded bg-neutral-focus hover:bg-base-100 transition duration-300"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, onMounted } from "vue";
import { useSessionStore } from "@/stores/session";
import { useAlertStore } from "@/stores/alertStore";
import { storeToRefs } from "pinia";

const props = defineProps({
  projectId: {
    type: Number,
    required: true,
  },
});

const emit = defineEmits(["closeModal", "addAlert", "removeAlert"]);

const sessionStore = useSessionStore();
const alertStore = useAlertStore();
const { user } = storeToRefs(sessionStore);

const alertType = ref("priceTarget");
const priceInput = ref("");
const frequency = ref("oneTime");
const email = ref("");
const discordWebhook = ref("");
const selectedAlertId = ref(null);
const showErrorMessage = ref(false);
const isRemoving = ref(false);
const choiceMade = ref(false);

const projectAlerts = computed(() =>
  alertStore.getAlertsByProject(props.projectId)
);

const hasExistingAlerts = computed(() => projectAlerts.value.length > 0);

const modalTitle = computed(() => {
  if (!choiceMade.value && hasExistingAlerts.value) return "Manage Alerts";
  return isRemoving.value ? "Remove Alert" : "Add Alert";
});

onMounted(() => {
  if (user.value && user.value.Email) {
    email.value = user.value.Email;
  }
  console.log("AddAlertModal mounted. projectId:", props.projectId);
});

const isFormValid = computed(() => {
  return priceInput.value && (email.value || discordWebhook.value);
});

const buttonClass = computed(() => {
  return isFormValid.value
    ? "bg-primary hover:bg-primary-focus"
    : "bg-neutral-focus hover:bg-base-100 cursor-not-allowed";
});

const buttonText = computed(() => {
  return isFormValid.value ? "Add Alert" : "Please add a contact method";
});

const handleChoice = (choice) => {
  choiceMade.value = true;
  isRemoving.value = choice === "remove";
};

const submitAlert = () => {
  if (isFormValid.value) {
    console.log("Submitting alert with projectId:", props.projectId);
    const alertData = {
      projectId: props.projectId,
      type: alertType.value,
      price: parseFloat(priceInput.value),
      frequency: frequency.value,
      email: email.value || undefined,
      discordWebhook: discordWebhook.value || undefined,
    };
    console.log("Alert data to be submitted:", alertData);
    emit("addAlert", alertData);
    closeModal();
  } else {
    showErrorMessage.value = true;
  }
};

const confirmRemove = () => {
  if (selectedAlertId.value) {
    console.log("Removing alert with ID:", selectedAlertId.value);
    emit("removeAlert", selectedAlertId.value);
    closeModal();
  } else {
    console.error("No alert selected for removal");
  }
};

const closeModal = () => {
  emit("closeModal");
};

const formatAlertDetails = (alert) => {
  return `${alert.type} alert at ${alert.price} (${alert.frequency})`;
};
</script>
