// Utility function to generate head data
import { computed } from "vue";

export function createPageMetadata(pageContent, siteDataStore) {
  return computed(() => {
    if (!pageContent.value || !siteDataStore.siteData) return null;

    const page = pageContent.value;
    const site = siteDataStore.siteData;

    return {
      title: `${page.title}`,
      meta: [
        {
          name: "description",
          content: page.metaDescription,
        },
        {
          property: "og:description",
          content: page.metaDescription,
        },
        {
          property: "og:image",
          content: page.ogImage,
        },
      ],
      script: [
        {
          type: "application/ld+json",
          json: {
            "@context": "https://schema.org",
            "@type": "Organization",
            name: site.title,
            url: `https://${site.url}`,
            logo: page.ogImage,
            sameAs: site.socialLinks.map((link) => link.url),
          },
        },
      ],
    };
  });
}
