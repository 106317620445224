<template>
  <div
    v-if="show"
    class="fixed inset-0 bg-base-300 bg-opacity-80 flex items-center justify-center mx-auto"
    @click.self="close"
  >
    <div class="w-100 bg-base-200 border border-base-content p-6 rounded-lg">
      <h2 class="text-2xl font-bold text-center text-base-content mt-6 mb-6">
        {{ hasPassword ? "Change Password" : "Add Password" }}
      </h2>
      <form @submit.prevent="changePassword">
        <div v-if="hasPassword" class="mb-4">
          <input
            type="password"
            v-model="currentPassword"
            class="w-full p-2 border border-neutral bg-base-100 rounded"
            placeholder="Current Password"
            required
          />
        </div>
        <div class="mb-4">
          <input
            type="password"
            v-model="newPassword"
            class="w-full p-2 border border-neutral bg-base-100 rounded"
            placeholder="New Password"
            required
          />
        </div>
        <div class="mb-4">
          <input
            type="password"
            v-model="confirmNewPassword"
            class="w-full p-2 border border-neutral bg-base-100 rounded"
            placeholder="Confirm New Password"
            required
          />
        </div>
        <div class="flex justify-center">
          <button
            type="submit"
            :class="buttonClass"
            :disabled="!isValid || isSubmitting"
          >
            {{ buttonText }}
          </button>
        </div>
      </form>
      <p
        v-if="message"
        class="mt-4 text-center"
        :class="{
          'text-green-500': !error,
          'text-red-500': error,
        }"
      >
        {{ message }}
      </p>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, watch } from "vue";
import axios from "axios";

const props = defineProps({
  show: Boolean,
  hasPassword: Boolean,
});

const emit = defineEmits(["close", "passwordChanged"]);

const currentPassword = ref("");
const newPassword = ref("");
const confirmNewPassword = ref("");
const message = ref("");
const error = ref(false);
const validationMessage = ref("");
const isSubmitting = ref(false);

const validatePassword = (password) => {
  const checks = [
    { regex: /[A-Z]/, message: "Password must contain an uppercase letter" },
    { regex: /[a-z]/, message: "Password must contain a lowercase letter" },
    { regex: /\d/, message: "Password must contain a number" },
    {
      regex: /[@$!%*?&#^()%]/,
      message: "Password must contain a special character",
    },
    { regex: /.{10,}/, message: "Password must be at least 10 characters" },
  ];

  for (let check of checks) {
    if (!check.regex.test(password)) {
      return check.message;
    }
  }
  return "";
};

watch(newPassword, (value) => {
  validationMessage.value = validatePassword(value);
});

const isValid = computed(() => {
  if (props.hasPassword) {
    return (
      currentPassword.value !== "" &&
      newPassword.value !== "" &&
      confirmNewPassword.value !== "" &&
      validationMessage.value === "" &&
      newPassword.value === confirmNewPassword.value
    );
  } else {
    return (
      newPassword.value !== "" &&
      confirmNewPassword.value !== "" &&
      validationMessage.value === "" &&
      newPassword.value === confirmNewPassword.value
    );
  }
});

const buttonText = computed(() => {
  if (props.hasPassword && currentPassword.value === "")
    return "Enter your current password";
  if (newPassword.value === "") return "Enter a new password";
  if (validationMessage.value) return validationMessage.value;
  if (
    newPassword.value !== confirmNewPassword.value &&
    confirmNewPassword.value !== ""
  )
    return "Passwords do not match";
  return props.hasPassword ? "Change Password" : "Add Password";
});

const buttonClass = computed(() => {
  const baseClass =
    "w-full my-2 px-4 py-2 text-base-content  rounded transition duration-300 ";
  return isValid.value
    ? baseClass + "bg-primary hover:bg-primary-focus"
    : baseClass + "bg-neutral-focus hover:bg-base-100 cursor-not-allowed";
});

const changePassword = async () => {
  if (!isValid.value) {
    message.value = "Please ensure all fields are filled correctly.";
    error.value = true;
    return;
  }

  isSubmitting.value = true;
  message.value = "";
  error.value = false;

  try {
    const endpoint = props.hasPassword ? "change-password" : "set-password";
    const payload = props.hasPassword
      ? {
          currentPassword: currentPassword.value,
          newPassword: newPassword.value,
        }
      : { password: newPassword.value };

    const response = await axios.post(
      `${process.env.VUE_APP_BACKEND_API_URL}/users/${endpoint}`,
      payload,
      { withCredentials: true }
    );

    if (response.status === 200) {
      message.value = props.hasPassword
        ? "Password changed successfully."
        : "Password added successfully.";
      error.value = false;
      setTimeout(() => {
        emit("passwordChanged");
        close();
      }, 3000);
    }
  } catch (err) {
    console.error("Error changing password:", err);
    message.value =
      err.response?.data?.error ||
      "Failed to change password. Please try again.";
    error.value = true;
  } finally {
    isSubmitting.value = false;
  }
};

const close = () => {
  currentPassword.value = "";
  newPassword.value = "";
  confirmNewPassword.value = "";
  message.value = "";
  error.value = false;
  validationMessage.value = "";
  emit("close");
};
</script>
