// blogStore.js
import { defineStore } from "pinia";
import axios from "axios";
import siteConfig from "@/siteConfig.json";
import { usePreloadStore } from "@/stores/preloadStore";
const CACHE_EXPIRATION = 60 * 60 * 1000;

export const useBlogStore = defineStore("blog", {
  state: () => ({
    blogPosts: [],
    categories: [],
    selectedKnowledgeLevel: "All", // Default initialization
    isLoading: false,
    error: null,
  }),

  getters: {
    getPostBySlug: (state) => (slug) => {
      return state.blogPosts.find((post) => post.slug === slug);
    },
  },

  actions: {
    initializeStore() {
      this.categories = siteConfig.blogs.categories || [];
      this.activeCategories = new Set(this.categories);
    },

    async fetchBlogPosts() {
      const preloadStore = usePreloadStore();
      if (preloadStore.preloadedData.blogPosts) {
        this.blogPosts = preloadStore.preloadedData.blogPosts;
        return;
      }

      const maxRetries = 5;
      const retryDelay = 300;
      const limit = 60;
      let offset = 0;
      let allBlogPosts = [];
      let morePostsAvailable = true;

      const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

      this.isLoading = true;
      this.error = null;

      const fetchBatch = async (offset, limit, attempt = 1) => {
        for (; attempt <= maxRetries; attempt++) {
          try {
            const response = await axios.get(
              `${process.env.VUE_APP_BACKEND_API_URL}/blogs?offset=${offset}&limit=${limit}`,
              {
                headers: { "X-API-KEY": process.env.VUE_APP_X_API_KEY },
              }
            );
            await delay(5);
            return response.data;
          } catch (error) {
            console.error(`Error fetching batch (Attempt ${attempt}):`, error);

            if (error.response?.status === 503 && attempt < maxRetries) {
              await delay(retryDelay);
            } else {
              throw new Error(
                error.message || "An error occurred while fetching blog posts"
              );
            }
          }
        }
      };

      try {
        while (morePostsAvailable) {
          const batch = await fetchBatch(offset, limit);

          if (batch && batch.length > 0) {
            allBlogPosts = allBlogPosts.concat(batch);
            offset += limit;
          } else {
            morePostsAvailable = false;
          }
        }

        this.blogPosts = allBlogPosts;
        this.isLoading = false;
      } catch (error) {
        this.error =
          error.message || "An error occurred while fetching blog posts";
        this.isLoading = false;
        console.error("Failed to fetch blog posts after multiple attempts");
      }
    },

    async fetchBlogPostBySlug(slug, maxRetries = 10) {
      this.isLoading = true;
      this.error = null;

      // First try to load from static JSON cache
      try {
        const response = await fetch(`/cached-blogs/${slug}.json`);
        if (response.ok) {
          const post = await response.json();
          this.isLoading = false;
          console.log("Loaded post from static cache:", slug);
          return post;
        }
      } catch (err) {
        console.warn("Failed to load from static cache:", err);
      }

      // If we already have the post in blogPosts array, use that
      const existingPost = this.blogPosts.find((post) => post.slug === slug);
      if (existingPost) {
        console.log("Found post in existing blogPosts:", slug);
        this.isLoading = false;
        return existingPost;
      }

      // Try localStorage cache
      const cachedPost = this.getCachedPost(slug);
      if (cachedPost) {
        this.isLoading = false;
        return cachedPost;
      }

      // Fall back to API
      for (let attempt = 0; attempt < maxRetries; attempt++) {
        try {
          const response = await axios.get(
            `${process.env.VUE_APP_BACKEND_API_URL}/blogs?slug=${slug}`,
            {
              headers: { "X-API-KEY": process.env.VUE_APP_X_API_KEY },
            }
          );

          this.isLoading = false;

          if (response.data && Object.keys(response.data).length > 0) {
            console.log("Fetched blog post from API:", response.data);
            this.cachePost(slug, response.data);
            return response.data;
          } else {
            console.error("Blog post not found for slug:", slug);
            return null;
          }
        } catch (error) {
          console.error(`Attempt ${attempt + 1} failed:`, error);
          if (attempt === maxRetries - 1) {
            this.error =
              error.message || "An error occurred while fetching the blog post";
            this.isLoading = false;
            throw error;
          }
          await new Promise((resolve) => setTimeout(resolve, 1000));
        }
      }
    },

    setActiveCategories(categories) {
      if (categories === "All") {
        this.activeCategories = new Set(this.categories);
      } else {
        this.activeCategories = new Set([categories]);
      }
    },

    setKnowledgeLevel(level) {
      const levelMap = {
        All: "All",
        Beginner: 1,
        Intermediate: 2,
        Advanced: 3,
      };

      this.selectedKnowledgeLevel = levelMap[level];
    },

    getCachedPost(slug) {
      const cachedData = localStorage.getItem(`blog_${slug}`);
      if (cachedData) {
        const { data, timestamp } = JSON.parse(cachedData);
        if (Date.now() - timestamp < CACHE_EXPIRATION) {
          return data;
        } else {
          localStorage.removeItem(`blog_${slug}`);
        }
      }
      return null;
    },

    cachePost(slug, data) {
      localStorage.setItem(
        `blog_${slug}`,
        JSON.stringify({
          data,
          timestamp: Date.now(),
        })
      );
    },
  },
});
