<template>
  <div class="p-8 bg-base-100 rounded-xl shadow-lg max-w-md mx-auto">
    <h2 class="text-2xl font-bold text-center text-base-content mb-6">
      Verify Your Email
    </h2>
    <p class="text-center text-base-content mb-4">
      We've sent a verification email to {{ email }}. Please check your inbox
      and click the verification link.
    </p>
    <p
      v-if="message"
      class="text-center mb-4"
      :class="{ 'text-green-500': !error, 'text-red-500': error }"
    >
      {{ message }}
    </p>
    <button
      @click="resendVerificationEmail"
      class="w-full px-4 py-2 text-base-content rounded transition duration-300"
      :class="{
        'bg-primary hover:bg-primary-focus': !isResendDisabled,
        'bg-neutral-focus cursor-not-allowed': isResendDisabled,
      }"
      :disabled="isResendDisabled"
    >
      {{ resendButtonText }}
    </button>
  </div>
</template>

<script>
import { ref, computed, onMounted, onUnmounted } from "vue";
import { useRoute } from "vue-router";
import siteConfig from "@/siteConfig";

export default {
  emits: ["verification-completed"],
  setup(props, { emit }) {
    const route = useRoute();
    const email = ref(route.query.email || "");
    const message = ref("");
    const error = ref(false);
    const resendCount = ref(0);
    const lastResendTime = ref(0);
    const remainingTime = ref(0);

    const cooldownPeriod = 60; // 60 seconds

    const isResendDisabled = computed(() => remainingTime.value > 0);

    const resendButtonText = computed(() => {
      if (isResendDisabled.value) {
        return `Resend in ${remainingTime.value}s`;
      }
      return "Resend Verification Email";
    });

    let countdownInterval;

    const startCountdown = () => {
      remainingTime.value = cooldownPeriod;
      countdownInterval = setInterval(() => {
        if (remainingTime.value > 0) {
          remainingTime.value--;
        } else {
          clearInterval(countdownInterval);
        }
      }, 1000);
    };

    const resendVerificationEmail = async () => {
      if (isResendDisabled.value) return;

      const baseUrl = process.env.VUE_APP_BACKEND_API_URL;
      const emailConfig = siteConfig.emails.verification;

      try {
        console.log(`Resending verification email to: ${email.value}`);
        const response = await fetch(`${baseUrl}/users/resend-verification`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          // body: JSON.stringify({ email: email.value }),
          body: JSON.stringify({
            email: email.value,
            sender: emailConfig.sender,
            subject: emailConfig.subject,
            body: emailConfig.body,
          }),
        });

        console.log(`Response status: ${response.status}`);
        const data = await response.json();
        console.log(`Response data:`, data);

        if (response.ok) {
          message.value = "Verification email resent. Please check your inbox.";
          error.value = false;
          resendCount.value++;
          startCountdown();
        } else {
          throw new Error(data.error || "Failed to resend verification email");
        }
      } catch (err) {
        console.error("Error resending verification email:", err);
        message.value = `Failed to resend verification email: ${err.message}`;
        error.value = true;
      }
    };

    const checkVerificationStatus = async () => {
      const baseUrl = process.env.VUE_APP_BACKEND_API_URL;
      try {
        const response = await fetch(`${baseUrl}/users/check-verification`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
          credentials: "include",
        });

        if (response.ok) {
          const data = await response.json();
          if (data.verified) {
            message.value = "Email verified successfully!";
            error.value = false;
            emit("verification-completed");
          }
        }
      } catch (err) {
        console.error("Error checking verification status:", err);
      }
    };

    onMounted(() => {
      if (Date.now() - lastResendTime.value < cooldownPeriod * 1000) {
        remainingTime.value = Math.ceil(
          (cooldownPeriod * 1000 - (Date.now() - lastResendTime.value)) / 1000
        );
        startCountdown();
      }

      // Start checking verification status periodically
      const statusCheckInterval = setInterval(checkVerificationStatus, 5000); // Check every 5 seconds

      // Clean up interval on component unmount
      onUnmounted(() => {
        clearInterval(statusCheckInterval);
        if (countdownInterval) {
          clearInterval(countdownInterval);
        }
      });
    });

    return {
      message,
      error,
      resendVerificationEmail,
      isResendDisabled,
      resendButtonText,
      email,
    };
  },
};
</script>
