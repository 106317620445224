<template>
  <div class="directory-grid-container">
    <!-- Show detail view if an item is selected or a project parameter is provided -->
    <transition name="slide-detail">
      <div v-if="selectedItem" class="directory-detail">
        <DirectoryDetail
          v-if="selectedItem"
          :key="selectedItem.id"
          :item="selectedItem"
          @close="closeDetail"
        />
      </div>
    </transition>

    <!-- Show grid if no specific project is selected -->
    <div v-if="!selectedItem" class="directory-grid" :style="gridStyle">
      <DirectoryCard
        v-for="item in sortedAndFilteredItems"
        :key="item.id"
        :item="item"
        :hasAlert="hasAlert(item.id)"
        :isFavorited="isFavorited(item.id)"
        :isLoggedIn="isLoggedIn"
        @click="onCardClick(item)"
      />
    </div>
  </div>
</template>

<script setup>
import { ref, computed, watch, onMounted } from "vue";
import { useSessionStore } from "@/stores/session";
import { useAlertStore } from "@/stores/alertStore";
import { useFavoriteStore } from "@/stores/favoriteStore";
import { storeToRefs } from "pinia";
import DirectoryCard from "./DirectoryCard.vue";
import DirectoryDetail from "./DirectoryDetail.vue";

const props = defineProps({
  items: Array,
  sortOption: String,
  activeCategories: Set,
  projectParam: String,
  gridColumnCount: Number,
});

const emits = defineEmits(["close"]);

const sessionStore = useSessionStore();
const alertStore = useAlertStore();
const favoriteStore = useFavoriteStore();

const { isLoggedIn } = storeToRefs(sessionStore);
// const { alerts } = storeToRefs(alertStore);
// const { favorites } = storeToRefs(favoriteStore);

const isLoading = ref(true);

const gridStyle = computed(() => {
  return {
    "grid-template-columns": `repeat(${props.gridColumnCount}, 1fr)`,
  };
});

// Computed property for sorted and filtered items
const sortedAndFilteredItems = computed(() => {
  // First filter by active categories
  let filteredItems = props.items.filter((item) =>
    item.categories.some((category) => props.activeCategories.has(category))
  );

  // Then sort the filtered items
  switch (props.sortOption) {
    case "Market Cap":
      return filteredItems.sort((a, b) => b.marketCap - a.marketCap);
    case "Name":
      return filteredItems.sort((a, b) => a.title.localeCompare(b.title));
    case "Newest":
      return filteredItems.sort(
        (a, b) => new Date(b.dateStarted) - new Date(a.dateStarted)
      );
    case "Oldest":
      return filteredItems.sort(
        (a, b) => new Date(a.dateStarted) - new Date(b.dateStarted)
      );
    default:
      return filteredItems;
  }
});

// Reactive variable to track the selected item
const selectedItem = ref(null);

// Track the scroll position
let savedScrollPosition = 0;

// Function to check if an item has an alert
const hasAlert = (itemId) => {
  return alertStore.hasAlert(itemId);
};

// Function to check if an item is favorited
const isFavorited = (itemId) => {
  return favoriteStore.isFavorited(itemId);
};

const loadUserData = async () => {
  if (isLoggedIn.value) {
    isLoading.value = true;
    try {
      await Promise.all([
        alertStore.getUserAlerts(),
        favoriteStore.getUserFavorites(),
      ]);
    } catch (error) {
      console.error("Error loading user data:", error);
    } finally {
      isLoading.value = false;
    }
  } else {
    isLoading.value = false;
  }
};

// Function to find and display the item based on the project parameter
const displayItemFromProjectParam = () => {
  if (props.items && props.items.length > 0 && props.projectParam) {
    // Decode the parameter and replace `-`, `_`, and `%20` with spaces
    const normalizedParam = decodeURIComponent(props.projectParam)
      .toLowerCase()
      .replace(/[-_]/g, " ")
      .replace(/\s+/g, " "); // Ensures multiple spaces are treated as a single space

    const foundItem = props.items.find(
      (item) => item.title.toLowerCase() === normalizedParam
    );

    if (foundItem) {
      selectedItem.value = foundItem;
      onCardClick(foundItem); // Trigger the onCardClick function to handle scrolling
    } else {
      console.warn(`No item found for projectParam: ${props.projectParam}`);
    }
  }
};

onMounted(() => {
  // Watch for changes in items to ensure display logic runs when data is available
  watch(
    () => props.items,
    (newItems) => {
      if (newItems.length > 0) {
        displayItemFromProjectParam();
      }
    },
    { immediate: true }
  );
});

watch(isLoggedIn, async (newValue) => {
  if (newValue) {
    await loadUserData();
  } else {
    // Clear data when user logs out
    alertStore.$reset();
    favoriteStore.$reset();
  }
});

watch(
  () => props.projectParam,
  (newValue) => {
    if (newValue) {
      displayItemFromProjectParam();
    } else {
      closeDetail();
    }
  }
);

// Function to handle card click
const onCardClick = (item) => {
  savedScrollPosition =
    window.pageYOffset || document.documentElement.scrollTop;
  selectedItem.value = item;

  const mobileOffset = 300; // Scroll 50 pixels down from the top on mobile
  const desktopOffset = 440; // Scroll 100 pixels down from the top on desktop

  const isMobile = window.innerWidth < 768;

  const scrollOffset = isMobile ? mobileOffset : desktopOffset;

  window.scrollTo({ top: scrollOffset, behavior: "smooth" });
};

// Function to close the detail view
const closeDetail = () => {
  selectedItem.value = null;
  emits("close");

  // Assuming a 500ms slide-out animation for the detail view
  setTimeout(() => {
    window.scrollTo({ top: savedScrollPosition, behavior: "smooth" });
  }, 500); // Match this delay to your transition duration
};
</script>

<style scoped>
.directory-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
  gap: 20px;
  width: 100%;
  max-width: 100%;
  box-sizing: border-box;
  cursor: pointer;
  /* more styles... */
}

/* Fade transition for the card grid */
.fade-enter-active {
  transition: opacity 0.2s ease;
}
.fade-leave-active {
  transition: opacity 0.2s ease;
}
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

/* Custom slide-up and slide-down transitions */
.slide-detail-enter-active {
  animation: slide-in 0.5s ease forwards;
}
.slide-detail-leave-active {
  animation: slide-out 0.5s ease forwards;
}

@keyframes slide-in {
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(0);
  }
}

@keyframes slide-out {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(80%);
  }
}
</style>
