<!-- SwapDropdown.vue -->
<template>
  <div class="relative w-full">
    <button
      @click="toggleDropdown"
      class="input input-bordered w-full flex justify-between items-center text-base-content bg-base-100"
    >
      <div class="grid grid-cols-8 w-full items-center">
        <img :src="selectedToken.image" class="h-6 w-6 col-span-1" />
        <div class="col-span-7 text-left w-full flex justify-between">
          <div class="text-base">{{ selectedToken.name }}</div>
          <div class="text-xs text-base-content mt-1 mr-3">
            Balance:
            {{ formatBalance(selectedToken.balance, selectedToken.symbol) }}
            {{ selectedToken.symbol }}
          </div>
        </div>
      </div>

      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
        class="h-5 w-5 text-base-content"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M19 9l-7 7-7-7"
        />
      </svg>
    </button>
    <ul
      class="absolute left-0 w-full bg-neutral rounded-md overflow-hidden shadow-lg z-100 dropdown-menu"
      v-show="dropdownOpen"
    >
      <li
        v-for="(token, index) in sortedTokens"
        :key="index"
        @click="selectToken(token)"
        class="cursor-pointer hover:bg-neutral-focus px-3 py-2 flex items-center"
      >
        <div class="flex justify-between w-full">
          <div class="flex items-center">
            <img :src="token.image" class="h-6 w-6 mr-3" />
            {{ token.name }}
          </div>
          <span class="text-xs text-base-content mt-1">
            {{ formatBalance(token.balance, token.symbol) }} {{ token.symbol }}
          </span>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import { computed, defineComponent } from "vue";

export default defineComponent({
  props: {
    tokens: Array,
    selectedToken: Object,
    dropdownOpen: Boolean,
    coinsStore: Object, // Ensure this prop is received
  },
  setup(props, { emit }) {
    // Move the computed property inside the setup function
    const sortedTokens = computed(() => {
      const tokensWithPrice = props.tokens.map((token) => {
        // Find the corresponding coin in the coinsStore
        const coin = props.coinsStore.coins.find(
          (c) => c.address === token.address
        );
        // Calculate USD value if coin info is found; otherwise, default to 0
        const usdValue = coin ? token.balance * coin.currentPriceCoinGecko : 0;
        return { ...token, usdValue };
      });

      // Sort tokens by USD value, then by name for tokens with 0 balance
      tokensWithPrice.sort((a, b) => {
        if (b.usdValue !== a.usdValue) return b.usdValue - a.usdValue;
        if (a.balance === 0 && b.balance === 0)
          return a.name.localeCompare(b.name);
        return 0;
      });

      return tokensWithPrice;
    });

    const toggleDropdown = () => {
      emit("update:dropdownOpen", !props.dropdownOpen); // Emit an event instead of modifying the prop directly
    };

    const selectToken = (token) => {
      emit("update:selectedToken", token);
      emit("update:dropdownOpen", false); // Close the dropdown when a token is selected
    };

    const formatBalance = (balance, symbol) => {
      // Define specific formatting for certain symbols
      const specialFormatting = {
        USDC: 2,
        USDT: 2,
        BONK: 0,
        CHONKY: 0,
        FOXY: 0,
      };

      // Check if the symbol has specific formatting
      const decimals = specialFormatting[symbol];

      // If specific formatting exists, use it; otherwise, default to 4 decimals
      return balance === 0
        ? "0"
        : parseFloat(balance).toFixed(decimals !== undefined ? decimals : 4);
    };

    // Return all the functions and computed properties to be used in the template
    return {
      toggleDropdown,
      selectToken,
      sortedTokens,
      formatBalance,
    };
  },
});
</script>

<style scoped>
.dropdown-menu {
  z-index: 1000;
  max-height: 280px; /* Adjust based on your needs */
  overflow-y: auto; /* Enables vertical scrolling */
  overflow-x: hidden; /* Prevents horizontal scrolling */
}

/* Style the scrollbar track (background) */
.dropdown-menu::-webkit-scrollbar-track {
  background: #2e2e2e; /* Dark grey track */
}

/* Style the scrollbar thumb (the moving part) */
.dropdown-menu::-webkit-scrollbar-thumb {
  background: #555; /* Lighter grey thumb */
  border-radius: 4px; /* Optional: Adds rounded corners to the scrollbar thumb */
}

/* Handle scrollbar thumb hover */
.dropdown-menu::-webkit-scrollbar-thumb:hover {
  background: #888; /* Darker grey on hover */
}

/* Set the width of the scrollbar */
.dropdown-menu::-webkit-scrollbar {
  width: 8px; /* Adjust the width of the scrollbar */
}

/* Firefox scrollbar styles */
.dropdown-menu {
  scrollbar-width: thin; /* "auto" or "thin" */
  scrollbar-color: #7e22ce #2e2e2e; /* thumb and track color */
}
</style>
