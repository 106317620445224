import { defineStore } from "pinia";
import axios from "axios";
import { usePreloadStore } from "@/stores/preloadStore";

export const useLearnStore = defineStore("learnStore", {
  state: () => ({
    courses: [],
    isLoading: false,
    error: null,
  }),
  actions: {
    async fetchCourses() {
      const preloadStore = usePreloadStore();
      if (preloadStore.preloadedData.courses) {
        this.courses = preloadStore.preloadedData.courses;
        return;
      }
      const maxRetries = 5; // Maximum number of retry attempts
      const retryDelay = 300; // Delay between retries in milliseconds

      // Helper function to delay execution
      const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

      // Reset state before fetching
      this.isLoading = true;
      this.error = null;

      for (let attempt = 1; attempt <= maxRetries; attempt++) {
        try {
          // console.log(`Fetching learn courses... (Attempt ${attempt})`);

          const response = await axios.get(
            `${process.env.VUE_APP_BACKEND_API_URL}/courses`,
            {
              headers: { "X-API-KEY": process.env.VUE_APP_X_API_KEY },
            }
          );

          // Update state with fetched data
          this.courses = response.data;
          this.isLoading = false;
          // console.log(
          //   `Successfully fetched ${this.courses.length} learn courses`
          // );
          return; // Exit the function if successful
        } catch (error) {
          console.error(
            `Error fetching learn courses (Attempt ${attempt}):`,
            error
          );

          if (
            error.response &&
            error.response.status === 503 &&
            attempt < maxRetries
          ) {
            // If it's a 503 error and not the last attempt, wait before retrying
            // console.log(`Retrying in ${retryDelay / 1000} seconds...`);
            await delay(retryDelay);
          } else {
            // If it's not a 503 error or it's the last attempt, set the error state
            this.error =
              error.message || "An error occurred while fetching learn courses";
            this.isLoading = false;
            break; // Exit the retry loop
          }
        }
      }

      // If we've exhausted all retries, log a final error message
      if (this.error) {
        console.error("Failed to fetch learn courses after multiple attempts");
      }
    },
  },
});
