<!-- TokenSwap.vue -->
<script setup>
// Import libraries
import { throttle } from "lodash";
import { useWallet } from "solana-wallets-vue";
import { WalletMultiButton } from "solana-wallets-vue";
import { Connection } from "@solana/web3.js";
import {
  ref,
  watch,
  onMounted,
  onBeforeUnmount,
  nextTick,
  defineProps,
  computed,
} from "vue";
import { toast } from "vue3-toastify";
import { useSessionStore } from "@/stores/session";
import { storeToRefs } from "pinia";

// Import functions
import { getQuote } from "@/utilities/jupiter/getQuote";
import { updateConnectedWalletTokenList } from "@/utilities/solana/tokenService";
import { validateAmount } from "@/utilities/forms/validation";

// Import components
import SwapDropdown from "./SwapDropdown.vue";
import ConfirmationModal from "./ConfirmationModal.vue";
import LoadingScreen from "./LoadingScreen.vue";
import SuccessMessage from "./SuccessMessage.vue";

// Import config data
import { useCoinsStore } from "@/stores/coins";
import { allCuratedTokens } from "@/config/allTokens.js";
import {
  checkSwapStatus,
  performSwapWithSimulation,
} from "@/utilities/jupiter/swap";

// Debounce the quote fetching
// const debouncedFetchNewQuote = debounce(fetchNewQuote, 500);

const rpcUrl = computed(() => {
  return user.value?.rpcUrl || process.env.VUE_APP_SOLANA_RPC;
});

// const isSwapReady = computed(() => {
//   return amountValue && !isInvalidAmount && swapQuote;
// });

const createConnection = () => {
  return new Connection(rpcUrl.value, "confirmed");
};

// Define constants
const SOL_MINT_ADDRESS = "So11111111111111111111111111111111111111112";
const BALANCE_UPDATE_INTERVAL = 60000; // 1 minute in milliseconds

const props = defineProps({
  allowedTokens: Array,
});

// Define approved tokens
const coinsStore = useCoinsStore();
const allowedTokensForFirstDropdown = allCuratedTokens;
// const allowedTokensForSecondDropdown = allCuratedTokens;
// let tokenObjectsForSecondDropdown = ref([]);
//const tokenObjectsForSecondDropdown = ref([]);

// Setup wallet
const { publicKey, connected } = useWallet();
const sessionStore = useSessionStore();
const { user } = storeToRefs(sessionStore);

// Initialize reactive variables
let addressValue = ref("");
let amountValue = ref("");
let tokens = ref([]);
let selectedToken1 = ref({
  address: SOL_MINT_ADDRESS,
  name: "SOL",
  image: "./images/coins/solana.webp",
  decimals: 9,
  balance: 0,
  symbol: "SOL",
});
let selectedToken2 = ref({
  address: "EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v",
  name: "USDC",
  image: "./images/coins/usdc.webp",
  decimals: 6,
  balance: 0,
  symbol: "USDC",
});
let dropdownOpen = ref(false);
let dropdownOpen2 = ref(false);
const dropdownRef1 = ref(null);
const dropdownRef2 = ref(null);
let isInvalidAmount = ref(false);
let showConfirmationModal = ref(false);
let isLoading = ref(false);
let showSuccessMessage = ref(false);
let transactionId = ref("");
let countdown = ref(10);
let countdownInterval = null;
let swapQuote = ref(null);

// Function to check if wallet connection is needed
const needsWalletConnection = () => {
  return (
    user.value &&
    ["view_only", "manual_connect"].includes(user.value.walletType) &&
    !connected.value
  );
};

// Function to check amount validity in the component
const checkAmountValidity = () => {
  if (connected.value && selectedToken1.value) {
    isInvalidAmount.value = validateAmount(
      amountValue.value,
      selectedToken1.value.balance
    );
  } else {
    isInvalidAmount.value = false;
  }
};

// Function to handle click outside the dropdown menus
const handleClickOutside = (event) => {
  nextTick(() => {
    // Ensure the DOM is updated
    if (dropdownRef1.value && !dropdownRef1.value.$el.contains(event.target)) {
      dropdownOpen.value = false;
    }
    if (dropdownRef2.value && !dropdownRef2.value.$el.contains(event.target)) {
      dropdownOpen2.value = false;
    }
  });
};

// Cache for storing token balances
const balanceCache = new Map();

// Throttled function to update balances
const throttledUpdateBalances = throttle(async () => {
  if (connected.value && publicKey.value) {
    const connection = createConnection();
    try {
      const updatedTokens = await updateConnectedWalletTokenList(
        allowedTokensForFirstDropdown,
        connection
      );
      tokens.value = updatedTokens;
      updatedTokens.forEach((token) => {
        balanceCache.set(token.address, token.balance);
      });
      updateSelectedTokenBalances();
    } catch (error) {
      console.error("Error updating wallet token list:", error);
      toast.error("Unable to fetch wallet data. Please try again later.", {
        autoClose: 5000,
        theme: "dark",
      });
    }
  }
}, BALANCE_UPDATE_INTERVAL);

// Function to update selected token balances from cache
const updateSelectedTokenBalances = () => {
  if (selectedToken1.value) {
    selectedToken1.value.balance =
      balanceCache.get(selectedToken1.value.address) || 0;
  }
  if (selectedToken2.value) {
    selectedToken2.value.balance =
      balanceCache.get(selectedToken2.value.address) || 0;
  }
};

// Function to get balance (from cache or fetch if necessary)
const getBalance = async (token) => {
  if (balanceCache.has(token.address)) {
    return balanceCache.get(token.address);
  }
  // If not in cache, trigger an update
  await throttledUpdateBalances();
  return balanceCache.get(token.address) || 0;
};

// Modify selectToken1 function
const selectToken1 = async (token) => {
  if (token.address === selectedToken2.value.address) {
    selectedToken2.value = { ...selectedToken1.value };
  }
  selectedToken1.value = { ...token, balance: await getBalance(token) };
  dropdownOpen.value = false;
  checkAmountValidity();
};

// Modify selectToken2 function
const selectToken2 = async (token) => {
  selectedToken2.value = { ...token, balance: await getBalance(token) };
  dropdownOpen2.value = false;
};

// Fetch tokens on mount
onMounted(async () => {
  if (connected.value && publicKey.value) {
    await throttledUpdateBalances();
  }
  initializeSelectedToken1();
  if (!coinsStore.coins.length) {
    await coinsStore.fetchCoins();
  }
  document.addEventListener("click", handleClickOutside);
});

watch(
  [connected, publicKey, user, rpcUrl],
  async ([newConnected, newPublicKey, newUser]) => {
    if (newConnected && newPublicKey && newUser) {
      await throttledUpdateBalances();
      initializeSelectedToken1();
    }
  }
);

const initializeSelectedToken1 = () => {
  if (tokens.value.length > 0) {
    // Find SOL in the list or use the first token as a fallback
    const solToken =
      tokens.value.find((token) => token.address === SOL_MINT_ADDRESS) ||
      tokens.value[0];
    selectedToken1.value = { ...solToken };
  }
};

// Set default selected token as SOL
selectedToken1.value = tokens.value.find(
  (token) => token.address === SOL_MINT_ADDRESS
);

let allowedTokensForDropdown = ref([]);
watch(
  [tokens, props.allowedTokens],
  ([newTokens, allowedTokens]) => {
    // Assuming allowedTokens is an array of token addresses or similar identifiers
    const updatedTokensWithBalance = allowedTokens.map((tokenProp) => {
      // Find the corresponding token in the newTokens array to get the balance
      const tokenData = newTokens.find(
        ({ address }) => address === tokenProp.address
      );
      if (tokenData) {
        // Return a new object combining the existing token prop data with the new balance
        return { ...tokenProp, balance: tokenData.balance };
      }
      return tokenProp; // Return the original token prop if no matching token data found
    });

    // Use the updated tokens with balance for the dropdown
    // This might require you to manage a local reactive property that reflects the updated allowedTokens
    // For example, if you have a local reactive array for the dropdown:
    allowedTokensForDropdown.value = updatedTokensWithBalance;
  },
  { deep: true, immediate: true }
);

watch(
  props.allowedTokens,
  (newTokenObjects) => {
    // Find the updated USDC token object by its address
    const updatedUSDC = newTokenObjects.find(
      (token) => token.address === selectedToken2.value.address
    );
    if (updatedUSDC) {
      // Update the selectedToken2 with the updated token object
      selectedToken2.value = {
        ...selectedToken2.value,
        balance: updatedUSDC.balance,
      };
    }
  },
  { deep: true, immediate: false }
);

const initializeSelectedToken2 = () => {
  // This function should be called after tokens are fetched or updated
  // Find the token in the updatedTokensWithBalance array (which has the latest balances)
  const updatedToken = allowedTokensForDropdown.value.find(
    (t) => t.address === selectedToken2.value.address
  );
  if (updatedToken) {
    // Update selectedToken2 with the latest balance
    selectedToken2.value = {
      ...selectedToken2.value,
      balance: updatedToken.balance,
    };
  }
};

watch(
  tokens,
  () => {
    // Ensure allowedTokensForDropdown is updated before initializing selectedToken2
    initializeSelectedToken2();
  },
  { immediate: true }
);

watch(dropdownOpen, (newVal) => {
  if (newVal) {
    window.addEventListener("click", handleClickOutside);
  } else {
    window.removeEventListener("click", handleClickOutside);
  }
});

watch(dropdownOpen2, (newVal) => {
  if (newVal) {
    window.addEventListener("click", handleClickOutside);
  } else {
    window.removeEventListener("click", handleClickOutside);
  }
});

// Function to update output amount based on the new quote
// Refactored function to update output amount
const updateOutputAmount = () => {
  nextTick(() => {
    // Ensure this runs after Vue updates the DOM
    const outputDiv = document.getElementById("outputAmount");
    if (outputDiv && swapQuote.value) {
      const formattedAmount = formatAmount(
        swapQuote.value.otherAmountThreshold /
          Math.pow(10, selectedToken2.value.decimals),
        selectedToken2.value.symbol
      );
      outputDiv.innerText = `${formattedAmount} ${selectedToken2.value.symbol}`;
    } else {
      outputDiv &&
        (outputDiv.innerText = "Enter a sell amount above to fetch a quote"); // Ensure outputDiv exists before setting its text
    }
  });
};

// Function to fetch new quote
const fetchNewQuote = async () => {
  if (selectedToken1.value && selectedToken2.value && amountValue.value) {
    //isLoading.value = true;
    swapQuote.value = await getQuote(
      parseFloat(amountValue.value),
      selectedToken1.value,
      selectedToken2.value
    );
    //isLoading.value = false;
    updateOutputAmount();
  }
};

watch(
  [selectedToken1, selectedToken2],
  async () => {
    if (amountValue.value > 0) {
      await fetchNewQuote();
    }
  },
  { deep: true }
);

// Existing watcher for amountValue
watch(
  amountValue,
  async (newAmount) => {
    // Perform real-time validation of the amount.
    checkAmountValidity();

    // Check if a new quote should be fetched.
    if (
      parseFloat(newAmount) > 0 &&
      selectedToken1.value &&
      selectedToken2.value
    ) {
      try {
        // Fetch a new quote based on the updated amount and selected tokens.
        swapQuote.value = await getQuote(
          parseFloat(newAmount),
          selectedToken1.value,
          selectedToken2.value
        );
        // Update the output display with the new quote information.
        updateOutputAmount();
      } catch (error) {
        console.error("Error fetching new quote:", error);
        // Optionally, handle any errors that occur during quote fetching.
      } finally {
        //isLoading.value = false; // Hide the loading indicator.
      }
    }
  },
  { immediate: false }
);

// Function to start the countdown
const startCountdown = () => {
  countdown.value = 60;
  countdownInterval = setInterval(() => {
    countdown.value -= 1;
    if (countdown.value <= 0) {
      clearInterval(countdownInterval);
      showSuccessMessage.value = false;
    }
  }, 1000);
};

// Watcher to start countdown when showSuccessMessage becomes true
watch(showSuccessMessage, (newVal) => {
  if (newVal) {
    startCountdown();
  } else if (countdownInterval) {
    clearInterval(countdownInterval);
  }
});

// Remove the event listener when the component is unmounted
onBeforeUnmount(() => {
  window.removeEventListener("click", handleClickOutside);
  document.removeEventListener("click", handleClickOutside);
  //removeDropdownListeners();
  if (countdownInterval) {
    clearInterval(countdownInterval);
  }
});

const initSwap = async () => {
  if (needsWalletConnection()) {
    return;
  }

  let swapReadyStatus = await checkSwapStatus(
    swapQuote,
    amountValue.value,
    selectedToken1.value
  );
  if (swapReadyStatus) {
    showConfirmationModal.value = true;
  }
};

const executeSwap = async () => {
  if (swapQuote.value && publicKey.value) {
    showConfirmationModal.value = false;
    isLoading.value = true;
    try {
      const connection = createConnection();
      const txid = await performSwapWithSimulation(
        swapQuote.value,
        selectedToken1.value,
        selectedToken2.value,
        amountValue.value,
        connection
      );
      if (txid) {
        transactionId.value = txid;

        // Update balances after swap
        await throttledUpdateBalances();
        updateSelectedTokenBalances();

        addressValue.value = "";
        amountValue.value = "";
        showSuccessMessage.value = true;
        setTimeout(() => {
          showSuccessMessage.value = false;
        }, 60000);
      } else {
        throw new Error("Swap transaction failed");
      }
    } catch (error) {
      console.error(error);
      toast.error("Transaction failed. Please try again.", {
        autoClose: 5000,
        theme: "dark",
      });
    } finally {
      isLoading.value = false;
    }
  }
};

// Function to handle cancel confirmation
const cancelConfirmation = () => {
  showConfirmationModal.value = false;
};

const handleCloseSuccessMessage = () => {
  showSuccessMessage.value = false;
  // Any additional logic to reset the form or state if needed
};

const formatAmount = (amount, symbol) => {
  const specialFormatting = {
    USDC: 2,
    USDT: 2,
    BONK: 0,
    CHONKY: 0,
    FOXY: 0,
  };

  // Default to 4 decimal places if the symbol is not found in specialFormatting
  const decimals = specialFormatting[symbol] ?? 4;
  return amount.toFixed(decimals);
};
</script>

<template>
  <div>
    <div
      class="flex flex-col items-left justify-center bg-base-200 p-6 rounded-xl shadow-md w-full max-w-md mx-auto"
    >
      <div v-if="!showConfirmationModal && !isLoading && !showSuccessMessage">
        <h3
          v-if="user && (connected || !needsWalletConnection())"
          class="mt-4 mb-2 text-red-500"
        >
          YOU'RE PAYING
        </h3>
        <!-- Sell Dropdown -->
        <SwapDropdown
          v-if="
            selectedToken1 &&
            tokens.length > 0 &&
            user &&
            (connected || !needsWalletConnection())
          "
          ref="dropdownRef1"
          :tokens="tokens"
          :selectedToken="selectedToken1"
          :dropdownOpen="dropdownOpen"
          :coinsStore="coinsStore"
          @update:dropdownOpen="dropdownOpen = $event"
          @update:selectedToken="selectToken1"
          class="first-dropdown text-base-content"
        />
        <!-- Input Field #1 -->
        <div
          class="input input-bordered w-full pt-3 mt-2 text-base-content bg-base-100 pl-5"
          v-if="!user"
        >
          Please login to continue.
        </div>
        <div
          v-else-if="!connected && needsWalletConnection()"
          class="input input-bordered w-full pt-3 mt-2 text-base-content bg-base-100 pl-5"
        >
          Please connect your wallet to continue.
        </div>
        <div
          v-else-if="!selectedToken1 && (connected || !needsWalletConnection())"
          class="input input-bordered w-full pt-3 mt-2 text-base-content bg-base-100 pl-5"
        >
          fetching wallet balances...
        </div>
        <!-- Input Field #2 -->
        <input
          v-if="user && (connected || !needsWalletConnection())"
          v-model="amountValue"
          type="number"
          @blur="checkAmountValidity"
          :class="{ 'border-red-500': isInvalidAmount }"
          class="input input-bordered w-full mt-2 text-base-content bg-base-100 pl-5"
          placeholder="amount"
        />
        <h3
          v-if="user && (connected || !needsWalletConnection())"
          class="mt-8 mb-2 text-green-500"
        >
          TO RECEIVE
        </h3>
        <!-- Buy Dropdown -->
        <SwapDropdown
          v-if="user && (connected || !needsWalletConnection())"
          ref="dropdownRef2"
          :tokens="allowedTokensForDropdown"
          :selectedToken="selectedToken2"
          :dropdownOpen="dropdownOpen2"
          :coinsStore="coinsStore"
          @update:dropdownOpen="dropdownOpen2 = $event"
          @update:selectedToken="selectToken2"
          class="second-dropdown text-base-content"
        />
        <!-- Output Field -->
        <div
          v-if="user && (connected || !needsWalletConnection())"
          class="input input-bordered w-full mt-2 text-base-content bg-neutral pl-5 flex pt-3"
          id="outputAmount"
        >
          <template v-if="swapQuote && swapQuote.value">
            {{
              formatAmount(
                swapQuote.value.otherAmountThreshold /
                  Math.pow(10, selectedToken2.decimals),
                selectedToken2.symbol
              ) +
              " " +
              selectedToken2.symbol
            }}
          </template>
          <template v-else>
            <span class="hidden sm:block"
              >Enter a sell amount above to fetch a quote</span
            >
            <span class="block sm:hidden">Enter a sell amount above</span>
          </template>
        </div>

        <!-- Wallet Multi Button -->
        <div class="mt-6 mb-6">
          <WalletMultiButton
            v-if="!connected && needsWalletConnection()"
            class="btn btn-base-100 w-full"
          />
        </div>

        <!-- Swap Button -->
        <button
          v-if="user && (connected || !needsWalletConnection())"
          @click="initSwap"
          class="w-full px-4 py-2 mb-2 text-base-content rounded bg-primary hover:bg-primary-focus transition duration-300"
          :disabled="!amountValue || isInvalidAmount || !swapQuote"
        >
          <template v-if="!amountValue">Enter an amount</template>
          <template v-else-if="isInvalidAmount">Insufficient Balance</template>
          <template v-else-if="!swapQuote">Fetching quote...</template>
          <template v-else>Continue</template>
        </button>
      </div>

      <ConfirmationModal
        :isVisible="showConfirmationModal"
        :amountValue="amountValue.toString()"
        :selectedToken="selectedToken1"
        :selectedToken2="selectedToken2"
        :swapQuote="swapQuote"
        @cancel="cancelConfirmation"
        @confirm="executeSwap"
      />

      <LoadingScreen :isLoading="isLoading" />

      <SuccessMessage
        :showMessage="showSuccessMessage"
        :transactionId="transactionId"
        :countdown="countdown"
        @close="handleCloseSuccessMessage"
      />
    </div>
    <p
      v-if="!showConfirmationModal && !isLoading && !showSuccessMessage"
      class="text-center text-sm text-base-100 mt-6"
    >
      Powered by
      <a
        href="https://jup.ag"
        rel="noopener nofollow"
        target="_blank"
        class="text-base-100 hover:text-base-content"
        >Jupiter Swap</a
      >
    </p>
  </div>
</template>

<style scoped>
h2 {
  font-size: 1.3rem;
  letter-spacing: 0.2px;
}
h3 {
  font-size: 0.8rem;
  letter-spacing: 0.3px;
}

h4 {
  font-size: 0.8rem;
  letter-spacing: 0.2px;
  text-transform: uppercase;
}

::placeholder {
  @apply text-base-content;
  opacity: 1; /* Firefox */
}

.desktop-message {
  display: none;
}

.mobile-message {
  display: block;
}

@media (min-width: 768px) {
  .desktop-message {
    display: block;
  }

  .mobile-message {
    display: none;
  }
}
</style>
