<template>
  <div v-if="isVisible">
    <h2 class="text-center mt-12 mb-8 text-base-content">
      You are swapping
      <span class="amount text-primary-focus">{{ amountValue }}&nbsp;</span>
      <span class="text-primary-focus"> {{ selectedToken.symbol }}</span>
      for at least
      <span class="amount text-primary-focus">
        {{
          swapQuote && swapQuote.otherAmountThreshold
            ? formatAmount(
                parseFloat(swapQuote.otherAmountThreshold) /
                  Math.pow(10, selectedToken2.decimals),
                selectedToken2.symbol
              )
            : "0"
        }}&nbsp;
      </span>
      <span class="text-primary-focus"> {{ selectedToken2.symbol }}</span>
    </h2>
    <h3
      class="flex align-middle justify-center text-center my-8 text-sm text-base-content"
    >
      Are you sure you want to continue?
    </h3>
    <button
      @click="emit('confirm')"
      class="w-full px-4 py-2 mb-2 text-base-content rounded bg-primary hover:bg-primary-focus transition duration-300"
    >
      Continue
    </button>
    <button
      @click="emit('cancel')"
      class="w-full px-4 py-2 text-base-content rounded bg-neutral-focus hover:bg-base-100 transition duration-300"
    >
      Cancel
    </button>
  </div>
</template>

<script setup>
defineProps({
  isVisible: Boolean,
  amountValue: String,
  selectedToken: Object,
  selectedToken2: Object,
  swapQuote: Object,
});

//console.log("token decimals", props.selectedToken2.decimals);

const emit = defineEmits(["cancel", "confirm"]);

const formatAmount = (amount, symbol) => {
  const specialFormatting = {
    USDC: 2,
    USDT: 2,
    BONK: 0,
    CHONKY: 0,
    FOXY: 0,
  };

  const decimals =
    specialFormatting[symbol] !== undefined ? specialFormatting[symbol] : 4;
  return amount === 0 ? "0" : parseFloat(amount).toFixed(decimals);
};
</script>

<style scoped>
h3 {
  font-size: 1.2rem;
  font-family: "Lato-Regular", sans-serif;
}

.amount {
  white-space: nowrap !important;
}
</style>
