<script setup>
import LoginModal from "@/components/login/LoginModal.vue";
import { useHead } from "@vueuse/head";
import { usePageData } from "@/utilities/general/usePageData";
const { headData } = usePageData("misc");
useHead(headData);
</script>

<template>
  <div class="hero mx-auto p-4 w-full overflow-x-hidden">
    <div class="hero-content w-full flex flex-col mt-40">
      <div class="w-68 sm:w-80 lg:w-116">
        <LoginModal />
      </div>
    </div>
  </div>
</template>
