<!-- FooterBar.vue | @/components/navigation/FooterBar.vue -->
<script setup>
import { onMounted, reactive, onBeforeUnmount, computed } from "vue";
import { useSiteDataStore } from "@/stores/siteDataStore";
import axios from "axios";
import ThemeSwitcher from "./ThemeSwitcher.vue";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import siteConfig from "@/siteConfig.json";

// Initialize the site data store
const siteDataStore = useSiteDataStore();

const footerStats = reactive({
  spotOne: { title: "", dataPoint: "", subtitle: "" },
  spotTwo: { title: "", dataPoint: "", subtitle: "" },
  spotThree: { title: "", dataPoint: "", subtitle: "" },
});

// Function to save footer stats to local storage
const saveFooterStatsToLocalStorage = () => {
  try {
    localStorage.setItem("footerStats", JSON.stringify(footerStats));
  } catch (error) {
    console.error("Error saving footer stats to local storage:", error);
  }
};

// Function to load footer stats from local storage
const loadFooterStatsFromLocalStorage = () => {
  try {
    const storedStats = localStorage.getItem("footerStats");
    if (storedStats) {
      const parsedStats = JSON.parse(storedStats);
      Object.keys(footerStats).forEach((key) => {
        if (parsedStats[key]) {
          footerStats[key] = parsedStats[key];
        }
      });
    }
  } catch (error) {
    console.error("Error loading footer stats from local storage:", error);
  }
};

const fetchFooterStats = async () => {
  const jitter = Math.random() * 100;
  const sleepDelay = 500 + jitter;
  await new Promise((resolve) => setTimeout(resolve, sleepDelay));

  let fetchSuccessful = false;

  for (const [key, value] of Object.entries(siteConfig.footerStats)) {
    if (value.endpoint) {
      try {
        const { data } = await axios.get(
          `${process.env.VUE_APP_BACKEND_API_URL}${value.endpoint}`,
          {
            headers: { "X-API-KEY": process.env.VUE_APP_X_API_KEY },
          }
        );
        footerStats[key] = {
          title: data.title,
          dataPoint: data.dataPoint,
          subtitle: data.subtitle,
        };
        fetchSuccessful = true;
      } catch (error) {
        console.error(`Error fetching ${key} stats:`, error);
      }
    }
    // brief sleep with jitter
    await new Promise((resolve) => setTimeout(resolve, sleepDelay));
  }

  if (fetchSuccessful) {
    saveFooterStatsToLocalStorage();
  }
};

let fetchStatsInterval = null;

// Computed properties for footer data
const footerLinks = computed(() => siteDataStore.siteData?.footerLinks || {});
const footerDisclaimer = computed(
  () => siteDataStore.siteData?.footerDisclaimer || ""
);
const socialLinks = computed(() => siteDataStore.siteData?.socialLinks || []);

const showFooterStatsSection = computed(() => {
  return siteConfig.footerStats.spotOne.endpoint !== "";
});

onMounted(() => {
  // check if site config has footer stats
  if (siteConfig.footerStats.spotOne.endpoint !== "") {
    loadFooterStatsFromLocalStorage(); // Load stored stats first
    fetchFooterStats(); // Then fetch fresh stats
    fetchStatsInterval = setInterval(fetchFooterStats, 60000); // Fetch every minute
  }
  if (!siteDataStore.siteData) {
    siteDataStore.fetchSiteData();
  }
});

onBeforeUnmount(() => {
  if (fetchStatsInterval) clearInterval(fetchStatsInterval);
});

const currentYear = new Date().getFullYear();
</script>

<template>
  <div
    class="max-w-full mb-0 pb-0 bg-base-300 border-neutral border-t-2 z-100 text-base-content"
  >
    <footer
      class="mx-auto mt-1 mb-6 md:mb-2 md:p-4 bg-base-300 block xl:flex flex-col xl:flex-row md:min-h-[10rem]"
    >
      <!-- Left Column for Social Links and Stats -->
      <div
        class="flex flex-col justify-end items-start pl-1 md:pl-2 mb-4 md:mb-0 md:flex-grow-0 md:flex-shrink-0 brand-column"
      >
        <!-- Dynamic Stats section -->
        <div
          v-if="showFooterStatsSection"
          class="stats stats-vertical md:stats-horizontal shadow bg-base-300 text-base-content"
        >
          <div v-for="(stat, key) in footerStats" :key="key" class="stat">
            <div class="stat-title text-base-content text-sm">
              {{ stat.title }}
            </div>
            <div class="stat-value text-3xl">{{ stat.dataPoint }}</div>
            <div class="stat-desc text-base-content">
              {{ stat.subtitle }}
            </div>
          </div>
        </div>

        <div class="mt-auto pl-4">
          <!-- Container for Social Links and Copyright -->
          <div class="mt-6">
            <h4 class="md:hidden text-base-content">Socials</h4>
            <!-- Dynamic Social Links -->
            <template v-for="link in socialLinks" :key="link.name">
              <a :href="link.url" rel="noopener" target="_blank" class="px-1">
                <font-awesome-icon
                  :icon="['fab', link.icon]"
                  class="w-8 h-8 hover:text-primary-focus"
                />
              </a>
            </template>
          </div>
          <!-- Desktop Copyright and Disclaimer -->
          <div class="hidden xl:block mt-6 pr-34">
            <p class="text-base-content" v-html="footerDisclaimer"></p>
          </div>
          <div class="hidden xl:block mt-6">
            &#169;{{ currentYear }} {{ siteDataStore.siteData?.title }}. All
            Rights Reserved.
          </div>
        </div>
      </div>

      <!-- Right Column for Menus -->
      <div
        class="flex-1 grid grid-cols-1 md:grid-cols-4 gap-4 pl-6 xl:pl-0 mt-8 xl:mt-4"
      >
        <!-- Dynamic Footer Links -->
        <div
          v-for="(column, columnName) in footerLinks"
          :key="columnName"
          class="block text-left"
        >
          <h4 class="text-base-content">{{ column.title }}</h4>
          <ul>
            <li v-for="link in column.links" :key="link.name">
              <router-link v-if="link.url.startsWith('/')" :to="link.url">{{
                link.name
              }}</router-link>
              <a v-else :href="link.url" target="_blank" rel="noopener">{{
                link.name
              }}</a>
            </li>
          </ul>
        </div>
      </div>

      <!-- Mobile Copyright and Disclaimer -->
      <div class="block xl:hidden mt-12 px-6 w-full">
        <p class="text-base-content" v-html="footerDisclaimer"></p>
      </div>
      <div class="block xl:hidden mt-2 px-6 mb-2 text-sm w-full">
        ©{{ currentYear }} {{ siteDataStore?.siteData?.title }}. All Rights
        Reserved.
      </div>
      <ThemeSwitcher />
    </footer>
  </div>
</template>

<style scoped>
h4 {
  font-size: 1rem;
  letter-spacing: 0.5px;
  text-transform: uppercase;
}
.brand-column {
  width: 50%;
  min-width: 200px;
}

li:hover {
  @apply text-primary-focus;
}
</style>
