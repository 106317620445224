export const allCuratedTokens = [
  "So11111111111111111111111111111111111111112",
  "EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v",
  "DezXAZ8z7PnrnRJjz3wXBoRgixCa6xjnB7YaB1pPB263",
  "H7ed7UgcLp3ax4X1CQ5WuWDn6d1pprfMMYiv5ejwLWWU",
  "6CNHDCzD5RkvBWxxyokQQNQPjFWgoHF94D7BmC73X6ZK",
  "EKpQGSJtjMFqKZ9KQanSqYXRcF8fBopzLHYxdM65zcjm",
  "AZsHEMXd36Bj1EMNXhowJajpUXzrKcK57wW4ZGXVa7yR",
  "Es9vMFrzaCERmJfrF4H2FYD4KCoNkY11McCe8BenwNYB",
  "ATLASXmbPQxBUYbxPsV97usA3fPQYEqzQBUHgiFCUsXx",
  "AURYydfxJib1ZkTir1Jn1J9ECYUtjb6rKQVmtYaixWPP",
  "GENEtH5amGSi8kHAtQoezp1XEXwZJ8vcuePYnXdKrMYz",
  "hntyVP6YFm1Hg25TN9WGLqM12b8TQmcknKrdu1oxWux",
  "iotEVVZLEywoTn1QdwNPddxPWszn3zFhEot3MfL9fns",
  "jtojtomepa8beP8AuQc6eXt5FriJwfFMwQx2v2f9mCL",
  "JUPyiwrYJFskUPiHa7hkeR8VUtAeFoSYbKedZNsDvCN",
  "MNDEFzGvMt87ueuHvVU9VcTqsAP5b3fTGPsHuuPA5ey",
  "MangoCzJ36AjZyKwVj3VnYU4GTonjfVEnJmvvWaxLac",
  "mb1eu7TzEc71KxDpsmsKoucSSuuoGLv1drys1oP2jh6",
  "CtzNArj8PACmPNbLXMgeBHC8bdx9LBPrFtix4z9AtwBf",
  "orcaEKTdK7LKz57vaAYr9QeNsVEPfiu6QeMU1kektZE",
  "poLisWXnNRwC6oBu1vHiuKQzFjGL4XDSu4g9qjz9qVk",
  "HZ1JovNiVvGrGNiiYvEozEVgZ58xaU3RKwX8eACQBCt3",
  "4k3Dyjzvzp8eMZWUXbBCjEvwSkkk59S5iCNLY3QrkX6R",
  "rndrizKT3MK1iimdxRdWabcF7Zg7AR5T4nud4EkHBof",
  "SHDWyBxihqiCj6YekG2GUr7wqKLeLAMK1gHZck9pL6y",
  "SLNDpmoWTVADgEdndyvWzroNL7zSi1dF9PC3xHGtPwp",
  "StepAscQoEioFxxWGnh2sLBDFp9d8rvKz2Yp39iDpyT",
  "a11bdAAuV8iB2fu7X6AxAvDTo1QZ8FXB3kk5eecdasp",
  "BLZEEuZUBVqFhj8adcCFPJvPVCiCyVmh3hkJMrU8KuJA",
  "bSo13r4TkiE4KumL71LsHTPpL2euBYLFx6h9HP3piy1",
  "CgnTSoL3DgY9SFHxcLj6CgCgKKoTBr6tp4CPAEWy25DE",
  "J1toso1uCk3RLmjorhTtrVwY9HJ7X8V9yYac6Y7kGCPn",
  "jucy5XJ76pHVvtPZb5TKRcGQExkwit2P5s4vY8UzmpC",
  "GDfnEsia2WLAW5t8yx2X5j2mkfA74i5kwGdDuZHt7XmG",
  "Fishy64jCaa3ooqXw7BHtKvYD8BTkSyAPh6RNE3xZpcN",
  "FoXyMu5xwXre7zEoSvzViRk3nGawHUp9kUh97y2NDhcq",
  "4vMsoUT2BWatFweudnQM1xedRLfJgJ7hswhcpz4xgBTy",
  "HxhWkVpk5NS4Ltg5nij2G671CKXFRKPK8vy271Ub4uEK",
  "LFNTYraetVioAPnGJht4yNg2aUZFXR776cMeN9VMjXp",
  "LSTxxxnJzKDFSLr4dUkPcmCf5VyryEqzPLz5j4bpxFp",
  "45EgCwcPXYagBC7KqBin4nCFgEZWN7f3Y6nACwxqMCWX",
  "mSoLzYCxHdYgdzU16g5QSh3i5K3z3KZK7ytfqcJm7So",
  "SCSuPPNUSypLBsV4darsrYNg4ANPgaGhKhsA3GmMyjz",
  "9gwTegFJJErDpWJKjPfLr2g2zrE3nL1v5zpwbtsk3c6P",
  "WENWENvqqNya429ubCdR81ZmD69brwQaaBYY6p3LCpk",
  "BaoawH9p2J8yUK9r5YXQs3hQwmUJgscACjmTkh8rMwYL",
  "74DSHnK1qqr4z1pXjLjPAVi8XFngZ635jEVpdkJtnizQ",
  "EchesyfXePKdLtoiZSL8pBe8Myagyy8ZRqsACNCFGnvp",
  "7i5KKsX2weiTkry7jA4ZwSuXGhs5eJBEjY8vVxR4pfRx",
  "AFbX8oGjGpmVFywbVouvhQSRmiW2aR1mohfahi4Y2AdB",
  "kiraZUmSnzgfVfhrdvNj6hxHFaPFTTUk8ioY98cbh6G",
  "66Qq2qS67K4L5xQ3xUTinCyxzdPeZQG1R1ipK8jrY7gc",
  "HxRELUQfvvjToVbacjr9YECdfQMUqGgPYB68jVDYxkbr",
  "NeonTjSjsuo3rexg9o6vHuMXw62f9V7zvmu8M8Zut44",
  "nosXBVoaCTtYdLvKY6Csb4AC8JCdQKKAaWYtx2ZMoo7",
  "DriFtupJYLTosbwoN8koMbEYSx54aFAVLddWsbksjwg7",
  "he1iusmfkpAdwvxLNGV8Y1iSbj4rUy6yMhEA3fotn9A",
  "5oVNBeEEQvYi1cX3ir8Dx5n1P7pdxydbGF2X4TxVusJm",
  "KMNo3nJsBXfcpJTVhZcXLW7RmTwTt4GVFE7suUBo9sS",
  "3S8qX1MsMqRbiwKg2cQyx7nis1oHMgaCuc9c4VfvVdPN",
  "oreoU2P8bN6jkk3jbaiVxYnG1dCXcYxwhwyK9jSybcp",
  "4LLbsb5ReP3yEtYzmXewyGjcir5uXtKFURtaEUVC2AHs",
  "7GCihgDB8fe6KNjn2MYtkzZcRjQy3t9GHdC8uHYmW2hr",
  "85VBFQZC9TZkfaptBWjvUw7YbZjy52A6mjtPGjstQAmQ",
  "ZEUS1aR7aX8DFFJf5QjWj2ftDDdNTroMNGo8YoQm3Gq",
  "ZEXy1pqteRu3n13kdyh4LwPQknkFk3GzmMYMuNadWPo",
];
