import { defineStore } from "pinia";
import axios from "axios";

const API_BASE_URL =
  process.env.VUE_APP_API_BASE_URL || "http://localhost:8080";

export const useFavoriteStore = defineStore("favorite", {
  state: () => ({
    favorites: [],
  }),
  actions: {
    async getUserFavorites() {
      try {
        const response = await axios.get(`${API_BASE_URL}/users/favorites`, {
          withCredentials: true,
        });
        this.favorites = response.data;
      } catch (error) {
        console.error("Error fetching favorites:", error);
      }
    },
    async addFavorite(projectId) {
      try {
        await axios.post(
          `${API_BASE_URL}/users/favorites`,
          { projectId },
          { withCredentials: true }
        );
        if (!this.favorites.includes(projectId)) {
          this.favorites.push(projectId);
        }
      } catch (error) {
        console.error("Error adding favorite:", error);
      }
    },
    async removeFavorite(projectId) {
      try {
        await axios.delete(`${API_BASE_URL}/users/favorites/${projectId}`, {
          withCredentials: true,
        });
        this.favorites = this.favorites.filter((id) => id !== projectId);
      } catch (error) {
        console.error("Error removing favorite:", error);
      }
    },
  },
  getters: {
    isFavorited: (state) => (projectId) => state.favorites.includes(projectId),
  },
});
