// auth.js
import { defineStore } from "pinia";

export const useAuthStore = defineStore("auth", {
  state: () => ({
    currentUser: null,
  }),
  actions: {
    async getCurrentUser() {
      return new Promise((resolve, reject) => {
        console.log(resolve, reject);
      });
    },
  },
});
