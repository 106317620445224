<template>
  <div class="min-h-screen text-base-content flex items-center justify-center">
    <div class="text-center">
      <div class="mb-8">
        <svg
          class="mx-auto h-32 w-32 text-gray-400 magnifying-glass animate-pulse"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
          />
        </svg>
      </div>
      <h1 class="text-4xl font-bold mb-4">
        {{ pageContent.content.title ?? "Not Found" }}
      </h1>
      <p class="text-xl mb-8">{{ pageContent.content.subtitle }}</p>
      <router-link
        :to="pageContent.content.buttonLink"
        class="w-full h-40 rounded-lg border-2 border-neutral p-0 m-0 -mt-4"
      >
        <button
          class="px-4 py-2 mb-2 text-base-content rounded bg-primary hover:bg-primary-focus transition duration-300 hover:scale-105"
        >
          {{ pageContent.content.buttonText }}
        </button>
      </router-link>
    </div>
  </div>
</template>

<script setup>
import { useHead } from "@vueuse/head";
import { usePageData } from "@/utilities/general/usePageData";
const { pageContent, headData } = usePageData("not-found");
useHead(headData);
</script>
