//functions.js

export const matchesSearch = (item, searchQuery) => {
  const query = searchQuery.toLowerCase();

  const tokenSymbols = item.tokens?.map((t) => t.symbol.toLowerCase()) || [];
  const keyPeopleNames = item.keyPeople?.map((p) => p.name.toLowerCase()) || [];
  const keywords = item.keywords?.map((k) => k.toLowerCase()) || [];

  return (
    item.title.toLowerCase().includes(query) ||
    tokenSymbols.some((symbol) => symbol.includes(query)) ||
    keyPeopleNames.some((name) => name.includes(query)) ||
    item.category.toLowerCase().includes(query) ||
    item.website.toLowerCase().includes(query) ||
    item.twitter.toLowerCase().includes(query) ||
    keywords.some((keyword) => keyword.includes(query))
  );
};

export const initializeFromQuery = (
  query,
  directoryStore,
  activeSortOption,
  gridColumnCount
) => {
  if (query.sort) {
    activeSortOption.value = query.sort;
  }

  if (query.categories) {
    const selectedCategories = query.categories.split(",");
    directoryStore.activeCategories.clear();
    selectedCategories.forEach((category) => {
      if (directoryStore.categories.includes(category)) {
        directoryStore.activeCategories.add(category);
      }
    });
  }

  if (query.columns) {
    gridColumnCount.value = parseInt(query.columns, 10);
  }
};

export const updateQueryParameters = (
  router,
  route,
  activeCategories,
  gridColumnCount
) => {
  const selectedCategories = Array.from(activeCategories).join(",");
  router.push({
    query: {
      ...route.query,
      categories: selectedCategories,
      columns: gridColumnCount,
    },
  });
};
