<!-- DisclaimerPage.vue | @/pages/DisclaimerPage.vue -->
<script setup>
import { computed } from "vue";
import { renderContent } from "@/utilities/general/renderContent";
import { useHead } from "@vueuse/head";
import { usePageData } from "@/utilities/general/usePageData";
const { pageContent, headData } = usePageData("disclaimer");
useHead(headData);

const renderedContent = computed(() => {
  if (pageContent.value && pageContent.value.content) {
    const { requiresRenderer, body } = pageContent.value.content;

    if (requiresRenderer && body) {
      return renderContent(body);
    }
  }
  return [];
});
</script>

<template>
  <div class="relative container mx-auto max-w-screen-xl text-center">
    <h1
      class="text-4xl lg:text-6xl font-bold max-w-2xl mx-auto mt-48 mb-12 text-base-content text-center"
    >
      {{ pageContent?.content?.title || "Disclaimer" }}
    </h1>
    <div class="max-w-lg mx-auto text-base-content text-left">
      <component
        v-for="(element, index) in renderedContent"
        :key="index"
        :is="element"
      />
    </div>
    <router-link
      to="/"
      class="bg-primary border-primary border-2 text-base-content px-6 py-2 rounded-full hover:bg-primary-focus hover:border-primary-focus custom-button"
    >
      I understand
    </router-link>
    <div class="emptySpace mt-0 lg:mt-32"></div>
  </div>
</template>
