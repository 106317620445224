<template>
  <div
    class="p-8 bg-base-200 text-base-content rounded-lg shadow-md max-w-2xl mx-auto"
  >
    <h2 class="text-4xl font-bold text-center mt-6 mb-6">User Profile</h2>
    <div v-if="isLoading" class="text-center text-base-content">
      <p>Loading...</p>
    </div>
    <div v-else-if="user" class="space-y-6">
      <div v-if="siteConfig.profileOptions.image" class="space-y-12 mx-auto">
        <EditProfilePicture
          :profileImageUrl="user.profileImageUrl"
          @image-updated="updateProfileImageUrl"
        />
      </div>
      <div class="space-y-4">
        <div v-if="siteConfig.profileOptions.name" class="space-y-2">
          <label for="name" class="text-lg font-semibold">Name</label>
          <input
            id="name"
            v-model="user.Name"
            :placeholder="user.Name"
            @blur="updateField('Name')"
            :class="[
              'w-full p-2 border border-neutral bg-base-100 rounded',
              { 'border-green-500': showSaved.Name },
            ]"
            autocomplete="off"
          />
          <p v-if="showSaved.Name" class="text-sm text-green-500">Saved</p>
          <div v-if="!showSaved.Name" class="h-6"></div>
        </div>
        <div v-if="siteConfig.profileOptions.email" class="space-y-2">
          <label for="email" class="text-lg font-semibold">Email</label>
          <input
            id="email"
            v-model="newEmail"
            :placeholder="user.Email"
            @blur="initiateEmailChange"
            class="w-full p-2 placeholder-base-content border border-neutral bg-base-100 rounded"
            autocomplete="off"
          />
          <p v-if="emailError" class="text-sm text-red-500">
            {{ emailError }}
          </p>
          <p
            v-else-if="user.Email && user.verified"
            class="text-sm text-green-500"
          >
            verified
          </p>
          <router-link
            v-else-if="user.Email && !user.verified"
            :to="'/verify-email?email=' + encodeURIComponent(user.Email)"
            class="text-sm text-red-500 hover:underline"
          >
            unverified
          </router-link>
        </div>
        <div v-if="siteConfig.profileOptions.password">
          <h3 class="text-lg font-semibold mt-6">Password</h3>
          <button
            @click="showChangePasswordModal = true"
            class="w-44 px-4 py-2 bg-base-100 text-base-content rounded hover:bg-neutral transition duration-300"
          >
            {{ hasPassword ? "Change Password" : "Add Password" }}
          </button>
        </div>
        <div v-if="siteConfig.profileOptions.walletAddress">
          <h3 class="text-lg font-semibold mt-8">Wallet Address</h3>
          <p class="text-sm">
            Wallet:
            <a
              v-if="user.publicKey"
              :href="`https://solscan.io/account/${user.publicKey}`"
              target="_blank"
              class="text-md hover:underline"
            >
              {{ formattedPublicKey }}
            </a>
          </p>
          <button
            @click="openWalletModal"
            class="w-44 px-4 py-2 bg-base-100 text-base-content rounded hover:bg-neutral transition duration-300"
          >
            {{ user.publicKey ? "Change Wallet" : "Add Wallet" }}
          </button>
        </div>
        <div v-if="siteConfig.profileOptions.walletType">
          <h3 class="text-lg font-semibold mt-8">Wallet Type</h3>
          <p class="text-sm">Wallet Type: {{ selectedWalletType }}</p>
          <button
            @click="openWalletTypeModal"
            class="w-44 px-4 py-2 bg-base-100 text-base-content rounded hover:bg-neutral transition duration-300"
          >
            Change Wallet Type
          </button>
          <p v-if="showSaved.walletType" class="text-sm text-green-500">
            Saved
          </p>
          <div v-if="!showSaved.walletType" class="h-10"></div>
        </div>
      </div>
    </div>
    <div v-else class="text-center text-base-content">
      <p>You are not logged in.</p>
      <button
        @click="login"
        class="mt-4 px-8 py-2 bg-neutral-focus text-base-content rounded hover:bg-base-100 transition duration-300"
      >
        <router-link :to="'/login'"> Login </router-link>
      </button>
    </div>

    <!-- Email Change Modal -->
    <EmailChangeModal
      :show="showEmailModal"
      :currentEmail="user?.Email"
      :newEmail="newEmail"
      @cancel="cancelEmailChange"
      @confirm="confirmEmailChange"
    />

    <!-- Wallet Change Modal -->
    <WalletChangeModal
      :show="showWalletModal"
      :connected="connected"
      :messageSigned="messageSigned"
      :showResendButton="showResendButton"
      :error="error"
      @close="closeWalletModal"
      @resend="signWalletMessage"
    />

    <!-- Wallet Type Selection Modal -->
    <div
      v-if="showWalletTypeModal"
      class="fixed inset-0 bg-base-300 bg-opacity-80 flex items-center justify-center mx-auto"
      @click="closeWalletTypeModal"
    >
      <div
        class="w-100 bg-base-200 border border-base-content rounded-lg"
        @click.stop
      >
        <WalletTypeSelection
          :isSolanaUser="!!user.publicKey"
          :existingPublicKey="user.publicKey"
          @wallet-type-selected="handleWalletTypeSelected"
        />
      </div>
    </div>

    <!-- Change Password Modal -->
    <ChangePasswordModal
      :show="showChangePasswordModal"
      :hasPassword="hasPassword"
      @close="showChangePasswordModal = false"
      @password-changed="handlePasswordChanged"
    />
  </div>
</template>

<script setup>
import { ref, onMounted, onUnmounted, computed, watch } from "vue";
import axios from "axios";
import eventBus from "@/eventBus";
import { useWallet } from "solana-wallets-vue";
import EditProfilePicture from "./EditProfilePicture.vue";
import EmailChangeModal from "./EmailChangeModal.vue";
import ChangePasswordModal from "./ChangePasswordModal.vue";
import WalletChangeModal from "./WalletChangeModal.vue";
import WalletTypeSelection from "./WalletTypeSelection.vue";
import { useSessionStore } from "@/stores/session";
import siteConfig from "@/siteConfig.json";

const session = useSessionStore();

const user = ref(null);
const hasPassword = ref(false);
const isLoading = ref(true);
const newEmail = ref("");
const showEmailModal = ref(false);
const emailError = ref("");
const emailErrorTimeout = ref(null);
const showWalletModal = ref(false);
const error = ref("");
const message = ref("");
const messageSigned = ref(false);
const showResendButton = ref(false);
const selectedWalletType = ref("");
const showWalletTypeModal = ref(false);
const viewOnlyAddress = ref("");
const privateKey = ref("");
const showSaved = ref({
  Name: false,
  walletType: false,
});
const showChangePasswordModal = ref(false);
const initialValues = ref({});

const { publicKey, connected, signMessage, disconnect } = useWallet();

const handlePasswordChanged = async () => {
  console.log("Password changed successfully");
  // Refresh the password status
  await fetchUserPasswordStatus();
  // Close the modal
  showChangePasswordModal.value = false;
};

const formattedPublicKey = computed(() => {
  if (user.value && user.value.publicKey) {
    const key = user.value.publicKey;
    return `${key.slice(0, 5)} . . . ${key.slice(-5)}`;
  }
  return "Not set";
});

// Debounce function to delay the modal opening
function debounce(func, wait) {
  let timeout;
  return function (...args) {
    clearTimeout(timeout);
    timeout = setTimeout(() => func.apply(this, args), wait);
  };
}

const openWalletTypeModal = debounce(() => {
  showWalletTypeModal.value = true;
}, 200); // 200ms delay

const closeWalletTypeModal = (reset = false) => {
  showWalletTypeModal.value = false;
  error.value = "";

  if (reset) {
    selectedWalletType.value = user.value.walletType;
  }

  viewOnlyAddress.value = "";
  privateKey.value = "";
  messageSigned.value = false;
};

const handleWalletTypeSelected = async (data) => {
  try {
    const response = await axios.post(
      `${process.env.VUE_APP_BACKEND_API_URL}/users/update-wallet-info`,
      data,
      { withCredentials: true }
    );
    console.log("Wallet info updated successfully:", response.data);
    user.value = response.data;
    selectedWalletType.value = (user.value.walletType || "view_only")
      .replace("_", " ")
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
    showSaved.value.walletType = true;
    setTimeout(() => {
      showSaved.value.walletType = false;
    }, 2000);
    closeWalletTypeModal();
  } catch (error) {
    console.error("Error updating wallet info:", error);
    error.value = "Failed to update wallet information. Please try again.";
  }
};

watch(connected, (newConnected) => {
  if (newConnected && showWalletModal.value) {
    // Reset messageSigned when a new wallet is connected
    messageSigned.value = false;
  }
});

const fetchUser = async () => {
  try {
    // console.log("Fetching user data...");
    const response = await axios.get(
      `${process.env.VUE_APP_BACKEND_API_URL}/auth/user`,
      { withCredentials: true }
    );
    // console.log("User data:", response.data);
    user.value = response.data;
    // Update the user in the session store
    session.setUser(response.data);
    selectedWalletType.value = (user.value.walletType || "view_only")
      .replace("_", " ")
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
    initialValues.value = JSON.parse(JSON.stringify(user.value));
  } catch (error) {
    // console.error("Error fetching user:", error);
    user.value = null;
  } finally {
    isLoading.value = false;
  }
};

const fetchUserPasswordStatus = async () => {
  try {
    const response = await axios.get(
      `${process.env.VUE_APP_BACKEND_API_URL}/users/has-password`,
      { withCredentials: true }
    );
    hasPassword.value = response.data.hasPassword;
  } catch (error) {
    console.error("Error fetching password status:", error);
  }
};

const updateProfileImageUrl = (newUrl) => {
  user.value.profileImageUrl = newUrl; // Update the user's profile image URL in the UI
};

const updateField = async (field) => {
  // console.log("Initial value:", initialValues.value[field]);
  // console.log("Current value:", user.value[field]);

  if (user.value[field] !== initialValues.value[field]) {
    try {
      await axios.post(
        `${process.env.VUE_APP_BACKEND_API_URL}/users/update-info`,
        { [field]: user.value[field] },
        { withCredentials: true }
      );
      console.log(`${field} updated successfully`);
      showSaved.value[field] = true;
      // Update the initial value after successful update
      initialValues.value[field] = user.value[field];
      setTimeout(() => {
        showSaved.value[field] = false;
      }, 2000);
    } catch (error) {
      console.error(`Error updating ${field}:`, error);
      // Revert the change in the UI
      user.value[field] = initialValues.value[field];
    }
  } else {
    console.log(`${field} unchanged, no update needed`);
  }
};

const initiateEmailChange = async () => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  if (newEmail.value && newEmail.value !== user.value.Email) {
    if (!emailRegex.test(newEmail.value)) {
      emailError.value = "Please enter a valid email.";
      return;
    }

    try {
      // Check if the new email already exists
      const response = await axios.post(
        `${process.env.VUE_APP_BACKEND_API_URL}/users/check-email`,
        { email: newEmail.value },
        { withCredentials: true }
      );

      if (response.status === 200) {
        // Email doesn't exist, show confirmation modal
        showEmailModal.value = true;
        emailError.value = ""; // Clear any existing error
      }
    } catch (error) {
      if (error.response && error.response.status === 409) {
        // Email already exists
        emailError.value =
          "This email is already in use. Please use a different email.";
        // Clear the error message after 10 seconds
        if (emailErrorTimeout.value) clearTimeout(emailErrorTimeout.value);
        emailErrorTimeout.value = setTimeout(() => {
          emailError.value = "";
        }, 10000);
      } else {
        emailError.value =
          "An error occurred while checking the email. Please try again.";
      }
    }
  }
};

const confirmEmailChange = async () => {
  const emailConfig = siteConfig.emails.emailChange;
  try {
    const response = await axios.post(
      `${process.env.VUE_APP_BACKEND_API_URL}/users/update-email`,
      {
        newEmail: newEmail.value,
        oldEmail: user.value.Email,
        sender: emailConfig.sender,
        subject: emailConfig.subject,
        body: emailConfig.body,
      },
      { withCredentials: true }
    );

    if (response.status === 200) {
      user.value.Email = newEmail.value;
      user.value.verified = false;
      showEmailModal.value = false;
      emailError.value = "";
      message.value =
        "Email updated successfully. Please check your new email for verification.";
    }
  } catch (error) {
    console.error("Error updating email:", error);
    emailError.value = "Failed to update email. Please try again.";
  }
};

const cancelEmailChange = () => {
  newEmail.value = user.value.Email;
  showEmailModal.value = false;
  emailError.value = ""; // Clear any existing error
};

const signWalletMessage = async () => {
  showResendButton.value = false;
  error.value = "";
  console.log("Starting message signing...");
  const messageToSign = new TextEncoder().encode(
    "Change wallet by verifying ownership. There is no network fee for this action."
  );

  const resendTimeout = setTimeout(() => {
    if (connected.value && !messageSigned.value) {
      showResendButton.value = true;
      console.log("Show resend button");
    }
  }, 10000); // 10 seconds

  try {
    // Check if the wallet's public key already exists in the database
    try {
      await axios.post(
        `${process.env.VUE_APP_BACKEND_API_URL}/users/check-public-key`,
        { publicKey: publicKey.value.toString() },
        { withCredentials: true }
      );
    } catch (checkError) {
      if (checkError.response && checkError.response.status === 409) {
        error.value =
          "This wallet is already in use. Please select another wallet.";
        await disconnect();
        clearTimeout(resendTimeout);
        return;
      }
      // If it's not a 409 error, we'll continue with the signing process
    }

    // If the check passes, proceed with signing
    const signedMessage = await signMessage.value(messageToSign);
    clearTimeout(resendTimeout);

    const data = {
      walletType: "manual_connect",
      publicKey: publicKey.value.toString(),
      signature: btoa(String.fromCharCode.apply(null, signedMessage)),
    };

    messageSigned.value = true;
    await updateWalletInfo(data);
  } catch (error) {
    clearTimeout(resendTimeout);
    console.error("Error in wallet change process:", error);

    if (error.message.includes("User rejected the request")) {
      error.value = "Message signing was rejected. Please try again.";
      showResendButton.value = true;
    } else {
      error.value = `Failed to change wallet: ${error.message}`;
      showResendButton.value = true;
    }
  }
};

const updateWalletInfo = async (data) => {
  try {
    const response = await axios.post(
      `${process.env.VUE_APP_BACKEND_API_URL}/users/update-wallet-info`,
      data,
      { withCredentials: true }
    );
    console.log("Wallet info updated successfully:", response.data);
    user.value = response.data;
    closeWalletModal();
  } catch (error) {
    console.error("Error updating wallet info:", error);
    error.value = "Failed to update wallet information. Please try again.";
  }
};

const openWalletModal = () => {
  showWalletModal.value = true;
  error.value = "";
  messageSigned.value = false;
  showResendButton.value = false;
  if (connected.value) {
    signWalletMessage();
  }
};

const closeWalletModal = () => {
  showWalletModal.value = false;
  error.value = "";
  messageSigned.value = false;
  showResendButton.value = false;
  if (connected.value) {
    disconnect();
  }
};

watch(connected, async (newConnected) => {
  if (newConnected && showWalletModal.value) {
    await signWalletMessage();
  }
});

const handleLogin = () => {
  console.log("Login event received");
  fetchUser();
};

onMounted(() => {
  if (session.user) {
    fetchUser();
  }
  fetchUserPasswordStatus();
  eventBus.on("login", handleLogin);
});

onUnmounted(() => {
  eventBus.off("login", handleLogin);
  if (emailErrorTimeout.value) clearTimeout(emailErrorTimeout.value);
});

const login = () => {
  eventBus.emit("showLogin");
};
</script>
