import { defineStore } from "pinia";
import axios from "axios";
import { debounce } from "lodash";

export const useSessionStore = defineStore("session", {
  state: () => ({
    user: null,
    theme: "customdark",
    isSideNavCollapsed: false,
    isLoading: false,
  }),
  actions: {
    setUser(user) {
      this.user = user;
    },
    clearUser() {
      this.user = null;
    },
    setTheme(theme) {
      this.theme = theme;
    },
    setSideNavCollapsed(value) {
      this.isSideNavCollapsed = value;
    },
    fetchUser: debounce(async function () {
      if (this.isLoading) return;
      this.isLoading = true;
      try {
        const response = await axios.get(
          `${process.env.VUE_APP_BACKEND_API_URL}/auth/user`,
          { withCredentials: true }
        );
        this.setUser(response.data);
        if (response.data.theme) {
          this.setTheme(response.data.theme);
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.clearUser();
        }
        console.error("Failed to fetch user:", error);
      } finally {
        this.isLoading = false;
      }
    }, 200), // 200ms debounce
  },
  getters: {
    isLoggedIn: (state) => !!state.user,
  },
});
