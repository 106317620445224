import { defineStore } from "pinia";
import axios from "axios";

export const useCoinsStore = defineStore("coins", {
  state: () => ({
    coins: [],
    isLoading: false,
    error: null,
  }),
  actions: {
    async fetchCoins() {
      const maxRetries = 5; // Maximum number of retry attempts
      const retryDelay = 300; // Delay between retries in milliseconds

      // Helper function to delay execution
      const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

      // Only fetch if coins are not already loaded
      if (this.coins.length === 0) {
        // Reset state before fetching
        this.isLoading = true;
        this.error = null;

        for (let attempt = 1; attempt <= maxRetries; attempt++) {
          try {
            console.log(`Fetching ecosystem coins... (Attempt ${attempt})`);

            const response = await axios.get(
              `${process.env.VUE_APP_BACKEND_API_URL}/coins`,
              {
                headers: { "X-API-KEY": process.env.VUE_APP_X_API_KEY },
              }
            );

            // Update state with fetched data
            this.coins = response.data;
            this.isLoading = false;
            console.log(
              `Successfully fetched ${this.coins.length} ecosystem coins`
            );
            return; // Exit the function if successful
          } catch (error) {
            console.error(
              `Error fetching ecosystem coins (Attempt ${attempt}):`,
              error
            );

            if (
              error.response &&
              error.response.status === 503 &&
              attempt < maxRetries
            ) {
              // If it's a 503 error and not the last attempt, wait before retrying
              console.log(`Retrying in ${retryDelay / 1000} seconds...`);
              await delay(retryDelay);
            } else {
              // If it's not a 503 error or it's the last attempt, set the error state
              this.error =
                error.message ||
                "An error occurred while fetching ecosystem coins";
              this.isLoading = false;
              break; // Exit the retry loop
            }
          }
        }

        // If we've exhausted all retries, log a final error message
        if (this.error) {
          console.error(
            "Failed to fetch ecosystem coins after multiple attempts"
          );
        }
      } else {
        console.log("Ecosystem coins already loaded, skipping fetch");
      }
    },
    async fetchCoinGeckoPriceInfo(token) {
      //console.log("fetching price");
      try {
        const response = await axios.get(token.coingeckoPriceInfo);
        const data = response.data;
        // Assuming the API returns an object with solana as key
        const priceData = data.solana;
        token.price = priceData.usd;
        token.volume24h = priceData.usd_24h_vol;
        token.change24h = priceData.usd_24h_change;
        token.percentChange24h = this.calculatePercentChange(
          token.price,
          token.change24h
        );
        //console.log("Price data:", priceData);
      } catch (error) {
        console.error("Error fetching CoinGecko price info:", error);
      }
    },

    calculatePercentChange(price, change) {
      //console.log("calcuating percent change");
      // Assuming 'change' is the change in price over the last 24 hours
      const oldPrice = price - change;
      //console.log((change / oldPrice) * 100);
      return (change / oldPrice) * 100;
    },

    async fetchCoinGeckoHistoryInfo(token) {
      //console.log("fetching history");
      try {
        const response = await axios.get(token.coingeckoHistoryInfo);
        const prices = response.data.prices;
        let high = 0;
        let low = Infinity;

        let finishedTimestamps = [];
        for (const [timestamp, price] of prices) {
          if (price > high) high = price;
          if (price < low) low = price;
          finishedTimestamps.push(timestamp);
        }

        token.high24h = high;
        token.low24h = low;
        //(token.high24h, token.low24h);
      } catch (error) {
        console.error("Error fetching CoinGecko history info:", error);
      }
    },

    async getTokensByAddress(addressesArray) {
      // Ensures coins data is up to date before proceeding
      if (this.coins.length === 0) {
        await this.fetchCoins(); // Make sure to await fetching coins if not yet loaded
      }

      let tokens = [];
      for (const address of addressesArray) {
        const token = this.coins.find((coin) => coin.address === address);
        if (token) {
          tokens.push({
            address: token.address,
            name: token.name,
            image: token.image,
            decimals: token.decimals,
            balance: token.balance, // Assuming balance is a property you want to include
            symbol: token.symbol,
          });
        }
      }
      // console.log("DEBUG ==============================================");
      // console.log("tokens", JSON.stringify(tokens));
      return tokens; // Make sure to return the tokens array
    },
  },
});
