<template>
  <div
    class="p-8 bg-base-200 text-base-content rounded-lg shadow-lg mx-auto w-116"
  >
    <h2 class="text-2xl font-bold text-center text-base-content mt-4 mb-6">
      Reset Password
    </h2>
    <form @submit.prevent="resetPassword">
      <div class="mb-4">
        <input
          type="password"
          v-model="newPassword"
          class="w-full p-2 border border-neutral bg-base-100 rounded"
          placeholder="New Password"
          required
        />
      </div>
      <div class="mb-4">
        <input
          type="password"
          v-model="confirmPassword"
          class="w-full p-2 border border-neutral bg-base-100 rounded"
          placeholder="Confirm New Password"
          required
        />
      </div>
      <div class="flex justify-center w-full">
        <button
          type="submit"
          :class="buttonClass"
          :disabled="!isValid || isSubmitting"
        >
          {{ buttonText }}
        </button>
      </div>
    </form>
    <p
      v-if="message"
      class="mt-4 text-center"
      :class="{ 'text-green-500': !error, 'text-red-500': error }"
    >
      {{ message }}
    </p>
  </div>
</template>

<script>
import { ref, onMounted, computed } from "vue";
import { useRoute, useRouter } from "vue-router";

export default {
  setup() {
    const route = useRoute();
    const router = useRouter();
    const token = ref("");
    const newPassword = ref("");
    const confirmPassword = ref("");
    const message = ref("");
    const error = ref(false);
    const isSubmitting = ref(false);

    onMounted(() => {
      token.value = route.query.token;
      if (!token.value) {
        message.value =
          "Invalid reset link. Please request a new password reset.";
        error.value = true;
      }
    });

    const validatePassword = (password) => {
      const checks = [
        {
          regex: /[A-Z]/,
          message: "Password must contain an uppercase letter",
        },
        { regex: /[a-z]/, message: "Password must contain a lowercase letter" },
        { regex: /\d/, message: "Password must contain a number" },
        {
          regex: /[@$!%*?&#^()%]/,
          message: "Password must contain a special character",
        },
        { regex: /.{10,}/, message: "Password must be 10 characters" },
      ];

      for (let check of checks) {
        if (!check.regex.test(password)) {
          return check.message;
        }
      }
      return "";
    };

    const isValid = computed(() => {
      return (
        newPassword.value === confirmPassword.value &&
        !validatePassword(newPassword.value) &&
        newPassword.value !== ""
      );
    });

    const buttonText = computed(() => {
      if (newPassword.value === "" && confirmPassword.value === "")
        return "Enter a new password";
      if (newPassword.value === "") return "Enter a new password";
      const validationMessage = validatePassword(newPassword.value);
      if (validationMessage) return validationMessage;
      if (newPassword.value !== confirmPassword.value)
        return "Passwords do not match";
      return "Reset Password";
    });

    const buttonClass = computed(() => {
      const baseClass =
        "w-full px-4 py-2 text-base-content rounded transition duration-300 ";
      return isValid.value
        ? baseClass + "bg-primary hover:bg-primary-focus"
        : baseClass + "bg-neutral-focus hover:bg-base-100  cursor-not-allowed";
    });

    const resetPassword = async () => {
      if (!isValid.value) {
        message.value =
          "Please ensure passwords match and meet all requirements.";
        error.value = true;
        return;
      }

      isSubmitting.value = true;
      message.value = "";
      error.value = false;

      try {
        const response = await fetch(
          `${process.env.VUE_APP_BACKEND_API_URL}/users/update-password`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              token: token.value,
              new_password: newPassword.value,
            }),
          }
        );

        if (response.ok) {
          message.value =
            "Password reset successful. You can now log in with your new password.";
          error.value = false;
          // Redirect to login page after a short delay
          setTimeout(() => router.push("/login"), 3000);
        } else {
          const errorData = await response.json();
          throw new Error(errorData.error || "Failed to reset password");
        }
      } catch (err) {
        message.value = err.message;
        error.value = true;
      } finally {
        isSubmitting.value = false;
      }
    };

    return {
      newPassword,
      confirmPassword,
      message,
      error,
      resetPassword,
      isSubmitting,
      buttonText,
      buttonClass,
      isValid,
    };
  },
};
</script>
