<template>
  <div class="min-h-screen py-12 px-4 sm:px-6 lg:px-8 text-base-content">
    <div class="max-w-3xl mx-auto">
      <h1
        class="text-4xl lg:text-6xl font-bold max-w-2xl mx-auto mt-20 md:mt-42 mb-12 text-base-content text-center"
      >
        {{ pageContent?.content?.title ?? "Welcome" }}
      </h1>
      <h3 class="text-2xl font-semibold text-center mb-8">
        {{ pageContent?.content?.subtitle ?? "Things to do here:" }}
      </h3>

      <div class="space-y-6">
        <a
          v-for="(option, index) in options"
          :key="index"
          :href="option.link"
          target="_blank"
          rel="noopener noreferrer"
          class="bg-base-100 shadow-md rounded-lg p-6 flex items-start space-x-4 transition duration-300 ease-in-out transform hover:scale-102 hover:shadow-lg"
        >
          <div class="flex-shrink-0">
            <font-awesome-icon
              :icon="option.icon"
              class="h-8 w-8 text-primary"
            />
          </div>
          <div>
            <h3 class="text-lg font-medium">
              {{ option.title }}
            </h3>
            <p class="mt-1">{{ option.description }}</p>
          </div>
        </a>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from "vue";
import { useHead } from "@vueuse/head";
import { usePageData } from "@/utilities/general/usePageData";
const { pageContent, headData } = usePageData("welcome");
useHead(headData);

const options = ref([
  {
    title: "Complete Your Profile",
    description:
      "Complete your profile to unlock all sign-in options and access to all site features.",
    icon: ["fas", "user-circle"],
    link: "/profile",
  },
  {
    title: "Save Your Favorite Projects",
    description:
      "Found a valuable project? Save it to your favorites for easy access later.",
    icon: ["fas", "star"],
    link: "/directory",
  },
  {
    title: "Set Up Project Alerts",
    description:
      "Receive notifications when a project's token reaches your set price targets or experiences significant daily movements.",
    icon: ["fas", "bell"],
    link: "/dashboard/alerts",
  },
  {
    title: "Bookmark Blogs",
    description:
      "Use the bookmark feature to save your favorite blog posts for future reading.",
    icon: ["fas", "bookmark"],
    link: "/dashboard/bookmarks",
  },
  {
    title: "Review and Modify Site Settings",
    description:
      "Customize the site to suit your preferences by adjusting your settings.",
    icon: ["fas", "cog"],
    link: "/settings",
  },
]);
</script>
