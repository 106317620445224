<script setup>
import VerifyEmail from "@/components/login/VerifyEmail.vue";
import { useHead } from "@vueuse/head";
import { usePageData } from "@/utilities/general/usePageData";
const { headData } = usePageData("misc");
useHead(headData);
</script>

<template>
  <div class="md:hero mx-auto p-4 w-full overflow-x-hidden">
    <div class="md:hero-content w-full flex flex-col mt-40">
      <VerifyEmail />
    </div>
  </div>
</template>

<style scoped>
/* Add your styles here */
</style>
