<template>
  <div
    class="p-8 bg-base-200 text-base-content rounded-lg shadow-lg max-w-md mx-auto"
  >
    <h2 class="text-2xl font-bold text-center text-base-content mb-6">
      Add a Password (Optional)
    </h2>
    <form @submit.prevent="handleSubmit">
      <div class="mb-4">
        <label for="password" class="block text-base-content mb-2"
          >Password</label
        >
        <input
          type="password"
          id="password"
          v-model="password"
          class="w-full p-2 border border-neutral bg-base-100 rounded"
          placeholder="Enter your password"
        />
      </div>
      <div class="mb-4">
        <label for="confirmPassword" class="block text-base-content mb-2"
          >Confirm Password</label
        >
        <input
          type="password"
          id="confirmPassword"
          v-model="confirmPassword"
          class="w-full p-2 border border-neutral bg-base-100 rounded"
          placeholder="Confirm your password"
        />
      </div>
      <button type="submit" :class="buttonClass" :disabled="isSubmitting">
        {{ buttonText }}
      </button>
    </form>
    <p
      v-if="message"
      class="mt-4 text-center"
      :class="{ 'text-green-500': !error, 'text-red-500': error }"
    >
      {{ message }}
    </p>
  </div>
</template>

<script>
import { ref, computed } from "vue";

export default {
  emits: ["password-set", "skip-password"],
  setup(props, { emit }) {
    const password = ref("");
    const confirmPassword = ref("");
    const message = ref("");
    const error = ref(false);
    const isSubmitting = ref(false);

    const validatePassword = (password) => {
      const checks = [
        {
          regex: /[A-Z]/,
          message: "Password must contain an uppercase letter",
        },
        { regex: /[a-z]/, message: "Password must contain a lowercase letter" },
        { regex: /\d/, message: "Password must contain a number" },
        {
          regex: /[@$!%*?&#^()%]/,
          message: "Password must contain a special character",
        },
        { regex: /.{10,}/, message: "Password must be 10 characters" },
      ];

      for (let check of checks) {
        if (!check.regex.test(password)) {
          return check.message;
        }
      }
      return "";
    };

    const isValid = computed(() => {
      return (
        password.value === confirmPassword.value &&
        !validatePassword(password.value)
      );
    });

    const buttonText = computed(() => {
      if (password.value === "" && confirmPassword.value === "") return "Skip";
      if (password.value === "") return "Enter a password";
      const validationMessage = validatePassword(password.value);
      if (validationMessage) return validationMessage;
      if (password.value !== confirmPassword.value)
        return "Passwords do not match";
      return "Set Password";
    });

    const buttonClass = computed(() => {
      const baseClass = "w-full px-4 py-2 rounded transition duration-300 ";
      return isValid.value
        ? baseClass + "mb-2 bg-primary text-base-content hover:bg-primary-focus"
        : baseClass + "bg-neutral-focus text-base-content hover:bg-base-100";
    });

    const handleSubmit = async () => {
      if (password.value === "" && confirmPassword.value === "") {
        emit("skip-password");
        return;
      }

      if (!isValid.value) {
        message.value =
          "Please ensure passwords match and meet all requirements.";
        error.value = true;
        return;
      }

      isSubmitting.value = true;
      message.value = "";
      error.value = false;

      try {
        emit("password-set", password.value);
        message.value = "Password set successfully!";
      } catch (err) {
        message.value = `Failed to set password: ${err.message}`;
        error.value = true;
      } finally {
        isSubmitting.value = false;
      }
    };

    return {
      password,
      confirmPassword,
      message,
      error,
      isSubmitting,
      handleSubmit,
      buttonText,
      buttonClass,
    };
  },
};
</script>
