// src/utilities/general/findPageBySlug.js

import { useSiteDataStore } from "@/stores/siteDataStore";

export function findPageBySlug(slug) {
  const siteDataStore = useSiteDataStore();

  // console.log("findPageBySlug called with slug:", slug);
  // console.log(
  //   "Current pagesData:",
  //   JSON.stringify(siteDataStore.pagesData, null, 2)
  // );

  if (!siteDataStore.pagesData || siteDataStore.pagesData.length === 0) {
    //console.warn("Pages data not loaded yet");
    return null;
  }

  const page = siteDataStore.pagesData.find((page) => page.slug === slug);

  if (!page) {
    // console.warn(`Page with slug "${slug}" not found`);
    // console.log(
    //   "Available slugs:",
    //   siteDataStore.pagesData.map((p) => p.slug)
    // );
    return null;
  }

  //console.log("Found page:", JSON.stringify(page, null, 2));
  return page;
}
