<template>
  <div class="card bg-base-100 shadow-xl">
    <div class="card-body">
      <div class="flex items-center mb-4">
        <font-awesome-icon
          class="nav-icon h-8 mr-2 -mt-1"
          :icon="['fas', icon]"
        />
        <h2 class="card-title text-primary-content ml-2 pt-1">{{ title }}</h2>
      </div>
      <p class="text-base-content mb-4">{{ description }}</p>
      <div class="card-actions justify-end w-full">
        <button
          @click="navigateTo(buttonAction)"
          class="w-full px-4 py-2 text-base-content rounded transition duration-300"
          :class="{
            'bg-primary hover:bg-primary-focus': active,
            'bg-neutral-focus cursor-not-allowed': !active,
          }"
          :disabled="!active"
        >
          {{ buttonText }}
        </button>
      </div>
    </div>
  </div>
</template>

<script setup>
import { defineProps } from "vue";

defineProps({
  icon: {
    type: String,
    required: true,
  },
  title: {
    type: String,
    required: true,
  },
  description: {
    type: String,
    required: true,
  },
  buttonText: {
    type: String,
    required: true,
  },
  buttonAction: {
    type: String,
    required: true,
  },
  active: {
    type: Boolean,
    required: true,
  },
});

const navigateTo = (path) => {
  console.log("Navigating to", path);
  window.location.href = path;
};
</script>
