<script setup>
import BlogOverview from "@/components/blog/BlogOverview";
import { useHead } from "@vueuse/head";
import { usePageData } from "@/utilities/general/usePageData";
const { pageContent, headData } = usePageData("blog");
useHead(headData);
</script>

<template>
  <div class="text-center mt-20 md:mt-42 mb-0 p-8 overflow-x-hidden">
    <h1 class="text-4xl lg:text-6xl text-base-content">
      {{ pageContent?.content?.title || "Blog" }}
    </h1>
    <p class="text-xl text-secondary-focus mb-0">
      {{ pageContent?.content?.subtitle || "Updates, articles, and insights" }}
    </p>
  </div>
  <blog-overview />
</template>
