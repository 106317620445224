import { Metaplex } from "@metaplex-foundation/js";
// import { TOKEN_PROGRAM_ID } from "@solana/spl-token";
import { PublicKey } from "@solana/web3.js";
import { useWallet } from "solana-wallets-vue";
import { useCoinsStore } from "@/stores/coins";
import { allCuratedTokens } from "@/config/allTokens.js";

const SOL_MINT_ADDRESS = "So11111111111111111111111111111111111111112";

export async function getNumberDecimals(connection, mintAddress) {
  const info = await connection.getParsedAccountInfo(
    new PublicKey(mintAddress)
  );
  const result = info.value?.data.parsed.info.decimals;
  return result;
}

export async function getSolBalance(connection, publicKey) {
  const balanceInLamports = await connection.getBalance(publicKey);
  const balanceInSol = balanceInLamports / Math.pow(10, 9); // Convert lamports to SOL
  return balanceInSol;
}

export async function fetchTokens(connection, publicKey) {
  if (!publicKey) {
    throw new Error("ownerAddress is undefined");
  }

  const coinsStore = useCoinsStore();

  // Fetch token data from the coins store first
  let tokensFromStore = await coinsStore.getTokensByAddress(allCuratedTokens);

  // Fetch account data for tokens that were not found in the store
  const tokensToFetchFromChain = allCuratedTokens.filter(
    (address) => !tokensFromStore.some((token) => token.address === address)
  );

  const accountInfos = await fetchMultipleAccounts(
    connection,
    tokensToFetchFromChain
  );

  const metaplex = new Metaplex(connection);
  let tokensFromChain = await Promise.all(
    accountInfos.map(async (accountInfo, index) => {
      if (accountInfo === null) {
        console.warn(
          `Account for token ${tokensToFetchFromChain[index]} not found`
        );
        return null;
      }
      const mintAddress = new PublicKey(tokensToFetchFromChain[index]);
      const decimals = await getNumberDecimals(connection, mintAddress);
      try {
        const nft = await metaplex.nfts().findByMint({ mintAddress });
        return {
          address: mintAddress.toString(),
          name: nft.json.name,
          image: nft.json.image,
          decimals,
          balance: accountInfo.data.parsed.info.tokenAmount.uiAmount
            ? accountInfo.data.parsed.info.tokenAmount.uiAmount
            : 0,
          symbol: nft.json.symbol,
        };
      } catch (e) {
        // console.error(`Error fetching NFT metadata for ${mintAddress}:`, e);
        return null; // Skip if metadata fetch fails and there's no fallback
      }
    })
  );

  // Filter out null tokens from the chain
  tokensFromChain = tokensFromChain.filter((token) => token !== null);

  // Combine tokens from the store and the chain
  const tokens = [...tokensFromStore, ...tokensFromChain];

  // Add SOL to the list
  tokens.push({
    address: SOL_MINT_ADDRESS,
    name: "Solana",
    image: "./images/coins/solana.webp",
    decimals: 9,
    balance: await getSolBalance(connection, publicKey),
    symbol: "SOL",
  });

  return tokens;
}

async function fetchMultipleAccounts(connection, mintAddresses) {
  const accountInfos = [];
  const batchSize = 100;
  for (let i = 0; i < mintAddresses.length; i += batchSize) {
    const batch = mintAddresses.slice(i, i + batchSize);
    const publicKeys = batch.map((mintAddress) => new PublicKey(mintAddress));
    const batchAccountInfos = await connection.getMultipleParsedAccounts(
      publicKeys
    );
    accountInfos.push(...batchAccountInfos.value);
  }
  return accountInfos;
}

export async function updateConnectedWalletTokenList(
  allowedTokensForFirstDropdown,
  connection
) {
  const { publicKey } = useWallet();

  if (publicKey.value) {
    let fetchedTokens = await fetchTokens(connection, publicKey.value);

    // Filter tokens based on allowed list and remove tokens with a zero balance
    let filteredTokens = fetchedTokens.filter(
      (token) =>
        allowedTokensForFirstDropdown.includes(token.address) &&
        token.balance > 0
    );

    // Always include SOL in the list if it has a balance greater than 0
    if (!filteredTokens.some((token) => token.address === SOL_MINT_ADDRESS)) {
      const solBalance = await getSolBalance(connection, publicKey.value);
      if (solBalance > 0) {
        const solToken = {
          address: SOL_MINT_ADDRESS,
          name: "Solana",
          image: "./images/coins/solana.webp",
          decimals: 9,
          balance: solBalance,
          symbol: "SOL",
        };
        filteredTokens.unshift(solToken);
      }
    } else {
      // Update SOL balance if it's already in the list
      const solTokenIndex = filteredTokens.findIndex(
        (token) => token.address === SOL_MINT_ADDRESS
      );
      if (solTokenIndex !== -1) {
        const solBalance = await getSolBalance(connection, publicKey.value);
        if (solBalance > 0) {
          filteredTokens[solTokenIndex].balance = solBalance;
        } else {
          // Remove SOL if the balance is 0
          filteredTokens.splice(solTokenIndex, 1);
        }
      }
    }

    return filteredTokens;
  }

  return []; // Return an empty array if publicKey.value is not available
}
