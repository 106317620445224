<template>
  <!-- Show detail view if an item is selected or a project parameter is provided -->
  <transition name="slide-detail">
    <AirdropDetail
      v-if="selectedItem"
      :key="selectedItem.id"
      :item="selectedItem"
      @close="closeDetail"
    />
  </transition>

  <!-- Show grid if no specific project is selected -->
  <div v-if="!selectedItem" class="airdrop-grid" :style="gridStyle">
    <AirdropCard
      v-for="item in sortedAndFilteredItems"
      :key="item.id"
      :item="item"
      @click="onCardClick(item)"
    />
  </div>
</template>

<script setup>
import { ref, computed, watch, onMounted } from "vue";
import AirdropCard from "./AirdropCard.vue";
import AirdropDetail from "./AirdropDetail.vue";

const props = defineProps({
  items: Array,
  sortOption: String,
  activeCategories: Set,
  projectParam: String,
  gridColumnCount: Number,
});

const emits = defineEmits(["close"]);

// watch(
//   () => props.sortOption,
//   (newValue, oldValue) => {
//     // console.log(
//     //   "Sort option changed in AirdropGrid from",
//     //   oldValue,
//     //   "to",
//     //   newValue
//     // );
//   }
// );

const gridStyle = computed(() => {
  return {
    "grid-template-columns": `repeat(${props.gridColumnCount}, 1fr)`,
  };
});

// Computed property for sorted and filtered items
const sortedAndFilteredItems = computed(() => {
  // First filter by active categories
  let filteredItems = props.items.filter((item) =>
    props.activeCategories.has(item.category)
  );

  // Then sort the filtered items
  switch (props.sortOption) {
    case "Market Cap":
      return filteredItems.sort((a, b) => b.marketCap - a.marketCap);
    case "Name":
      return filteredItems.sort((a, b) => a.title.localeCompare(b.title));
    case "Newest":
      return filteredItems.sort(
        (a, b) => new Date(b.dateStarted) - new Date(a.dateStarted)
      );
    case "Oldest":
      return filteredItems.sort(
        (a, b) => new Date(a.dateStarted) - new Date(b.dateStarted)
      );
    default:
      return filteredItems;
  }
});

// Reactive variable to track the selected item
const selectedItem = ref(null);

// Function to find and display the item based on the project parameter
const displayItemFromProjectParam = () => {
  if (props.projectParam) {
    const foundItem = props.items.find(
      (item) => item.title.toLowerCase() === props.projectParam.toLowerCase()
    );
    selectedItem.value = foundItem;
  }
};

onMounted(displayItemFromProjectParam);
watch(() => props.projectParam, displayItemFromProjectParam);

// Function to handle card click
const onCardClick = (item) => {
  selectedItem.value = item;
};

// Function to close the detail view
const closeDetail = () => {
  selectedItem.value = null;
  emits("close");
};
</script>

<style scoped>
.airdrop-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
  gap: 20px;
  cursor: pointer;
  /* more styles... */
}

/* Fade transition for the card grid */
.fade-enter-active {
  transition: opacity 0.2s ease;
}
.fade-leave-active {
  transition: opacity 0.2s ease;
}
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

/* Custom slide-up and slide-down transitions */
.slide-detail-enter-active {
  animation: slide-in 0.5s ease forwards;
}
.slide-detail-leave-active {
  animation: slide-out 0.5s ease forwards;
}

@keyframes slide-in {
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(0);
  }
}

@keyframes slide-out {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(80%);
  }
}
</style>
