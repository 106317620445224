<template>
  <div class="bg-base-200 shadow-lg rounded-t-3.5xl p-6 md:p-8 lg:p-12">
    <!-- X Close Button (Top Right Corner) -->
    <div class="flex justify-end">
      <img
        :src="item.image"
        alt="item.title"
        class="block md:hidden w-20 h-20 mr-4 rounded-full"
      />
      <button
        @click="$emit('close')"
        class="text-base-content hover:text-base-content ml-auto"
      >
        <font-awesome-icon class="w-8 h-8" :icon="['fas', 'circle-xmark']" />
      </button>
    </div>
    <!-- Header with Title and Image -->
    <div class="flex items-center md:-mt-8 mb-4">
      <img
        :src="item.image"
        alt="item.title"
        class="hidden md:block w-20 h-20 mr-4 rounded-full"
      />
      <div class="mt-4">
        <h2 class="text-2xl font-bold text-base-content">
          {{ item.title }}
        </h2>
        <p class="text-base-content">{{ item.slogan }}</p>
      </div>
    </div>

    <!-- Description and Notes with Grid Layout -->
    <div class="mb-4 text-base-content">
      <p class="text-base-content mb-4">{{ item.description }}</p>

      <!-- Grid Layout for Bull Case, VS, and Bear Case -->
      <div
        class="block md:grid grid-cols-8 gap-2 items-start justify-center mt-8"
      >
        <div
          class="text-sm col-span-4 border-neutral border-2 rounded-xl p-5 md:p-8 pt-10 mb-8 md:mb-0"
        >
          <h3 class="text-green-500 font-bold">How</h3>
          <p class="text-base-content">{{ item.how }}</p>
        </div>
        <div
          class="text-sm col-span-4 border-neutral border-2 rounded-xl p-5 md:p-8 pt-10"
        >
          <h3 class="text-red-500 font-bold">When</h3>
          <p class="text-base-content">{{ item.when }}</p>
        </div>
      </div>
    </div>

    <!-- Key People -->
    <div
      class="block md:grid grid-cols-5 gap-2 items-start justify-center my-8 md:my-12"
    >
      <div class="col-span-2 mb-8 md:mb-0">
        <h4 class="text-lg font-bold text-base-content">Key People</h4>
        <ul>
          <li
            v-for="person in item.keyPeople"
            :key="person.name"
            class="mb-8 md:mb-0 text-base-content"
          >
            <a
              :href="person.twitter"
              target="_blank"
              rel="noopener nofollow"
              class="hover:text-primary-focus"
            >
              {{ person.name }} - {{ person.title }}
            </a>
          </li>
        </ul>
      </div>

      <!-- Market Cap and Supporter Counter -->
      <div class="col-span-1 mb-8 md:mb-0 text-base-content">
        <h4 class="text-lg font-bold text-base-content">Status</h4>
        <p class="status text-base-content">{{ item.status }}</p>
      </div>
      <div class="col-span-1 mb-8 md:mb-0 text-base-content">
        <h4 class="text-lg font-bold text-base-content">Date Started</h4>
        <p class="text-base-content">
          {{ formatDateStarted(item.dateStarted) }}
        </p>
      </div>
      <!-- <div class="col-span-1 text-base-content">
        <h4
          v-if="item.tokens && item.tokens.length"
          class="text-lg font-bold text-base-content"
        >
          Token Price
        </h4>
        <p class="text-base-content" v-if="item.tokens && item.tokens.length">
          {{ tokenPrice }} USD / {{ item.tokens[0].symbol }}
        </p>
      </div> -->
      <!-- <div>
        <h4 class="text-lg font-bold">Bull Counter</h4>
        <p>{{ item.selfIdentifiedSupportersCounter }}</p>
      </div> -->
    </div>

    <!-- Links and Social Media -->
    <h4 class="text-lg font-bold text-base-content">Links</h4>
    <div class="flex space-x-4 mb-12 text-base-content">
      <a
        v-if="item.website"
        :href="item.website"
        target="_blank"
        rel="noopener nofollow"
        class="hover:text-primary-focus"
        ><font-awesome-icon class="w-7 h-7" :icon="['fas', 'globe']"
      /></a>
      <a
        v-if="item.twitter"
        :href="item.twitter"
        target="_blank"
        rel="noopener nofollow"
        class="hover:text-primary-focus"
        ><font-awesome-icon class="w-7 h-7" :icon="['fab', 'twitter']"
      /></a>
      <a
        v-if="item.discord"
        :href="item.discord"
        target="_blank"
        rel="noopener nofollow"
        class="hover:text-primary-focus"
        ><font-awesome-icon class="w-7 h-7" :icon="['fab', 'discord']"
      /></a>
      <a
        v-if="item.developers"
        :href="item.developers"
        target="_blank"
        rel="noopener nofollow"
        class="hover:text-primary-focus"
        ><font-awesome-icon class="w-7 h-7" :icon="['fas', 'code']"
      /></a>
      <a
        v-if="item.coinGecko"
        :href="item.coinGecko"
        target="_blank"
        rel="noopener nofollow"
        class="hover:text-primary-focus"
        ><font-awesome-icon class="w-7 h-7" :icon="['fas', 'magnifying-glass']"
      /></a>
    </div>

    <div class="spacer mb-12"></div>
    <!-- Close Button -->
    <button
      @click="$emit('close')"
      class="btn btn-base-100 bg-base-300 hover:bg-base-content border-base-content hover:border-base-content"
    >
      Close
    </button>
  </div>
</template>

<script setup>
import { defineProps } from "vue";
//import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

defineProps({
  item: Object,
});

// Format Date Started
const formatDateStarted = (dateString) => {
  const date = new Date(dateString);
  return date.toLocaleString("default", { month: "long", year: "numeric" });
};
</script>

<style scoped>
.status {
  text-transform: capitalize;
}
h3,
h4 {
  font-size: 1rem;
  text-transform: uppercase;
  letter-spacing: 0.4px;
}
.price-chart {
  color: white !important;
}

.cg-widget a {
  color: white !important;
}

.cg-container {
  color: white !important;
}

.price-chart,
.price-chart * {
  color: white !important;
}
</style>
