<template>
  <div class="bg-base-200 shadow-lg rounded-t-3.5xl p-6 md:p-8 lg:p-12">
    <!-- X Close Button (Top Right Corner) -->
    <div class="flex justify-end">
      <img
        :src="item.image"
        alt="item.title"
        class="block md:hidden w-20 h-20 mr-4 rounded-full"
      />
      <button
        @click="$emit('close')"
        class="text-base-content hover:text-base-content ml-auto -mt-12 md:mt-0"
      >
        <font-awesome-icon class="w-8 h-8" :icon="['fas', 'circle-xmark']" />
      </button>
    </div>
    <!-- Header with Title and Image -->
    <div class="flex items-center md:-mt-8 mb-4">
      <img
        :src="item.image"
        :alt="item.title"
        class="hidden md:block w-20 h-20 mr-4 rounded-full"
      />
      <div class="flex items-center flex-grow">
        <h2 class="text-2xl font-bold text-base-content mr-2">
          {{ item.title }}
        </h2>
        <div class="flex items-center">
          <button
            v-if="isLoggedIn && showFavoritesFeature"
            @click="toggleFavorite"
            class="text-base-content hover:text-primary-focus flex items-center justify-center ml-2 -mt-3"
            :class="{ 'text-primary-focus': isFavorited }"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              class="w-6 h-6 fill-current"
            >
              <path
                d="M12 2L15.09 8.26L22 9.27L17 14.14L18.18 21.02L12 17.77L5.82 21.02L7 14.14L2 9.27L8.91 8.26L12 2Z"
              />
            </svg>
          </button>
          <button
            v-if="
              isLoggedIn &&
              item.tokens &&
              item.tokens.length > 0 &&
              showAlertsFeature
            "
            @click="openAlertModal"
            class="text-base-content hover:text-primary-focus flex items-center justify-center ml-2 -mt-3"
            :class="{ 'text-primary-focus': hasAlert }"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              class="w-6 h-6 fill-current"
            >
              <path
                d="M12 22c1.1 0 2-.9 2-2h-4c0 1.1.9 2 2 2zm6-6v-5c0-3.07-1.63-5.64-4.5-6.32V4c0-.83-.67-1.5-1.5-1.5s-1.5.67-1.5 1.5v.68C7.64 5.36 6 7.92 6 11v5l-2 2v1h16v-1l-2-2zm-2 1H8v-6c0-2.48 1.51-4.5 4-4.5s4 2.02 4 4.5v6z"
              />
            </svg>
          </button>
          <transition name="fade">
            <div v-if="showFavoritedMessage">
              <span
                v-if="showFavoritedMessage"
                class="ml-1 -mt-2 text-sm font-bold text-success bg-transparent px-2 py-1 rounded"
              >
                saved
              </span>
            </div>
          </transition>
          <transition name="fade">
            <div v-if="showAlertMessage">
              <span
                v-if="showAlertMessage"
                class="ml-1 -mt-2 text-sm font-bold text-success bg-transparent px-2 py-1 rounded"
              >
                alert set
              </span>
            </div>
          </transition>
        </div>
      </div>
    </div>
    <p class="text-base-content">{{ item.slogan }}</p>

    <!-- Description and Notes with Grid Layout -->
    <div class="mb-4 text-base-content">
      <p class="text-base-content mb-4">{{ item.description }}</p>

      <!-- Grid Layout for Bull Case, VS, and Bear Case -->
      <div
        class="block md:grid grid-cols-9 gap-2 items-start justify-center mt-8"
      >
        <div
          class="text-sm col-span-4 border-neutral border-2 rounded-xl p-5 md:p-8 pt-10"
        >
          <h3 class="text-green-500 font-bold">Bulls</h3>
          <p class="text-base-content">{{ item.bullNotes }}</p>
        </div>
        <div
          class="text-2xl font-bold col-span-1 text-center mt-4 mb-4 md:mt-16 vs-sign"
        >
          VS
        </div>
        <div
          class="text-sm col-span-4 border-neutral border-2 rounded-xl p-5 md:p-8 pt-10"
        >
          <h3 class="text-red-500 font-bold">Bears</h3>
          <p class="text-base-content">{{ item.bearNotes }}</p>
        </div>
      </div>
    </div>

    <!-- Key People -->
    <div
      class="block md:grid grid-cols-5 gap-2 items-start justify-center my-8 md:my-12"
    >
      <div v-if="item.keyPeople.length >= 1" class="col-span-2 mb-8 md:mb-0">
        <h4 class="text-lg font-bold mb-4 text-base-content">Key People</h4>
        <ul>
          <li
            v-for="person in item.keyPeople"
            :key="person.name"
            class="mb-2 md:mb-1 text-base-content"
          >
            <a
              :href="person.twitter"
              target="_blank"
              rel="noopener nofollow"
              class="hover:text-primary-focus"
            >
              {{ person.name }} - {{ person.title }}
            </a>
          </li>
        </ul>
      </div>

      <!-- Market Cap and Supporter Counter -->
      <div class="col-span-1 mb-8 md:mb-0 text-base-content">
        <h4
          v-if="item.tokens.length >= 1 || item.marketCapNFTs >= 1"
          class="text-lg font-bold text-base-content"
        >
          Total Market Cap
        </h4>
        <p
          v-if="item.tokens.length >= 1 || item.marketCapNFTs >= 1"
          class="text-base-content"
        >
          {{ formattedMarketCap }}
        </p>
      </div>

      <div class="col-span-1 mb-8 md:mb-0 text-base-content">
        <h4 class="text-lg font-bold text-base-content">Date Started</h4>
        <p class="text-base-content">
          {{ formatDateStarted(item.dateStarted) }}
        </p>
      </div>
      <div class="col-span-1 text-base-content">
        <!-- <h4
          v-if="item.tokens && item.tokens.length"
          class="text-lg font-bold text-base-content"
        >
          Token Price
        </h4>
        <p v-if="item.tokens && item.tokens.length">
          {{ tokenPrice }} USD / {{ item.tokens[0].symbol }}
        </p> -->
      </div>
      <!-- <div>
        <h4 class="text-lg font-bold">Bull Counter</h4>
        <p>{{ item.selfIdentifiedSupportersCounter }}</p>
      </div> -->
    </div>

    <!-- Tokens Table -->
    <div v-if="item.tokens && item.tokens.length > 0" class="my-8">
      <h4 class="text-lg font-bold text-base-content">Tokens</h4>
      <table class="table-auto w-full mb-8 text-base-content">
        <thead class="text-left bg-base-100">
          <tr>
            <th class="p-4">Token Name</th>
            <th class="p-4">Price (USD)</th>
            <th class="p-4">Market Cap</th>
            <th class="p-4 hidden lg:table-cell">24H Volume</th>
            <th class="p-4 hidden lg:table-cell">% Change 24H</th>
            <th class="p-4 hidden lg:table-cell">High 24H</th>
            <th class="p-4 hidden lg:table-cell">Low 24H</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(token, index) in tokenDetails"
            :key="index"
            :class="index % 2 === 0 ? 'bg-neutral-focus' : 'bg-neutral'"
          >
            <td class="p-4">{{ token.name }}</td>
            <td class="p-4">${{ formatTokenPrice(token.priceUSD) }}</td>
            <td class="p-4">${{ formatBigNumber(token.marketCap) }}</td>
            <td class="p-4 hidden lg:table-cell">
              ${{ formatBigNumber(token.volume24h) }}
            </td>
            <td class="p-4 hidden lg:table-cell">
              {{ token.percentChange24h }}
            </td>
            <td class="p-4 hidden lg:table-cell">
              ${{ formatTokenPrice(token.high24h) }}
            </td>
            <td class="p-4 hidden lg:table-cell">
              ${{ formatTokenPrice(token.low24h) }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <!-- NFT Collections Table -->
    <div
      v-if="item.marketCapNFTs && item.marketCapNFTs.length > 0"
      class="my-8"
    >
      <h4 class="text-lg font-bold text-base-content">Collections</h4>
      <table class="table-auto w-full mb-8 text-base-content">
        <thead class="text-left bg-base-100">
          <tr>
            <th class="p-4">Collection Name</th>
            <th class="p-4">Floor Price (SOL)</th>
            <th class="p-4 hidden lg:table-cell">
              Average Price 24 Hours (SOL)
            </th>
            <th class="p-4">Market Cap (USD)</th>
            <th class="p-4 hidden lg:table-cell">Total Volume (SOL)</th>
            <th class="p-4 hidden lg:table-cell">Total Supply</th>
            <th class="p-4 hidden lg:table-cell">Quantity Listed</th>
            <th class="p-4 hidden lg:table-cell">Unique Holders</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(collection, index) in projectCollections"
            :key="collection.name"
            :class="index % 2 === 0 ? 'bg-neutral-focus' : 'bg-neutral'"
          >
            <td class="p-4">{{ collection.name }}</td>
            <td class="p-4">{{ collection.floorPrice }}</td>
            <td class="p-4 hidden lg:table-cell">
              {{ collection.avgPrice24hr }}
            </td>
            <td class="p-4">{{ formatBigNumber(collection.marketCap) }}</td>
            <td class="p-4 hidden lg:table-cell">
              {{ formatBigNumber(Number(collection.totalVolume)) }}
            </td>
            <td class="p-4 hidden lg:table-cell">
              {{ collection.totalSupply }}
            </td>
            <td class="p-2 hidden lg:table-cell">
              {{ collection.listedCount }}
            </td>
            <td class="p-2 hidden lg:table-cell">
              {{ collection.uniqueHolders }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <!-- Links and Social Media -->
    <h4 class="text-lg font-bold text-base-content">Links</h4>
    <div class="w-full flex flex-wrap mb-12 text-base-content">
      <a
        v-if="item.website"
        :href="item.website"
        target="_blank"
        rel="noopener nofollow"
        class="hover:text-primary-focus m-2"
        ><font-awesome-icon class="w-7 h-7" :icon="['fas', 'globe']"
      /></a>
      <a
        v-if="item.twitter"
        :href="item.twitter"
        target="_blank"
        rel="noopener nofollow"
        class="hover:text-primary-focus m-2"
        ><font-awesome-icon class="w-7 h-7" :icon="['fab', 'twitter']"
      /></a>
      <a
        v-if="item.discord"
        :href="item.discord"
        target="_blank"
        rel="noopener nofollow"
        class="hover:text-primary-focus m-2"
        ><font-awesome-icon class="w-7 h-7" :icon="['fab', 'discord']"
      /></a>
      <a
        v-if="item.telegram"
        :href="item.telegram"
        target="_blank"
        rel="noopener nofollow"
        class="hover:text-primary-focus m-2"
        ><font-awesome-icon class="w-7 h-7" :icon="['fab', 'telegram']"
      /></a>
      <a
        v-if="item.docs"
        :href="item.docs"
        target="_blank"
        rel="noopener nofollow"
        class="hover:text-primary-focus m-2"
        ><font-awesome-icon class="w-7 h-7" :icon="['fas', 'book-open']"
      /></a>
      <a
        v-if="item.tokenomics"
        :href="item.tokenomics"
        target="_blank"
        rel="noopener nofollow"
        class="hover:text-primary-focus m-2"
        ><font-awesome-icon class="w-7 h-7" :icon="['fas', 'chart-pie']"
      /></a>
      <a
        v-if="item.github"
        :href="item.github"
        target="_blank"
        rel="noopener nofollow"
        class="hover:text-primary-focus m-2"
        ><font-awesome-icon class="w-7 h-7" :icon="['fab', 'github']"
      /></a>
      <a
        v-if="item.developers"
        :href="item.developers"
        target="_blank"
        rel="noopener nofollow"
        class="hover:text-primary-focus m-2"
        ><font-awesome-icon class="w-7 h-7" :icon="['fas', 'code']"
      /></a>
    </div>

    <!-- Trading View Graph -->
    <div
      v-if="
        item.tokens &&
        item.tokens.length &&
        !noChartAvailable.includes(item.tokens[0].symbol)
      "
      class="hidden md:block tradingview-widget-container"
      style="height: 100%; width: 100%"
    >
      <div
        id="tradingview_42642"
        style="height: calc(100% - 32px); width: 100%"
      ></div>
      <div class="tradingview-widget-copyright">
        <a
          href="https://www.tradingview.com/"
          rel="noopener nofollow"
          target="_blank"
        >
          <span class="text-neutral-focus"
            >Track all markets on TradingView</span
          >
        </a>
      </div>
    </div>

    <div class="spacer mb-12"></div>
    <!-- Close Button -->
    <button
      @click="$emit('close')"
      class="btn btn-base-100 text-base-content border-base-content hover:border-base-content ext-base-content rounded bg-neutral-focus hover:bg-base-100 transition duration-300"
    >
      Close
    </button>
  </div>
  <!-- <AddAlertModal
    v-if="showAlertModal"
    :isRemoving="hasAlert"
    :projectId="item.id"
    @closeModal="closeAlertModal"
    @addAlert="addAlert"
    @removeAlert="removeAlert"
  /> -->
  <AddAlertModal
    v-if="showAlertModal"
    :projectId="item.id"
    @closeModal="closeAlertModal"
    @addAlert="addAlert"
    @removeAlert="removeAlert"
  />
</template>

<script setup>
import siteConfig from "@/siteConfig";
import { ref, computed, onMounted } from "vue";
import { useCoinsStore } from "@/stores/coins";
import { useCollectionStore } from "@/stores/collections";
import { useSessionStore } from "@/stores/session";
import { useFavoriteStore } from "@/stores/favoriteStore";
import { useAlertStore } from "@/stores/alertStore";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { storeToRefs } from "pinia";
import AddAlertModal from "./AddAlertModal.vue";

// Declare emits
defineEmits(["close"]);

const props = defineProps({
  item: Object,
});

const showAlertsFeature = computed(() => siteConfig.pagesEnabled.alerts);
const showFavoritesFeature = computed(() => siteConfig.pagesEnabled.favorites);

const sessionStore = useSessionStore();
const favoriteStore = useFavoriteStore();
const alertStore = useAlertStore();
const { isLoggedIn } = storeToRefs(sessionStore);
const { favorites } = storeToRefs(favoriteStore);
const { alerts } = storeToRefs(alertStore);

const showFavoritedMessage = ref(false);
const showAlertMessage = ref(false);
const showAlertModal = ref(false);

const isFavorited = computed(() => favoriteStore.isFavorited(props.item.id));
const hasAlert = computed(() => alertStore.hasAlert(props.item.id));

const toggleFavorite = async () => {
  if (!isLoggedIn.value) return;

  try {
    if (isFavorited.value) {
      await favoriteStore.removeFavorite(props.item.id);
    } else {
      await favoriteStore.addFavorite(props.item.id);
      showFavoritedMessage.value = true;
      setTimeout(() => {
        showFavoritedMessage.value = false;
      }, 2000);
    }
  } catch (error) {
    console.error("Error toggling favorite:", error);
  }
};

// const toggleAlert = async () => {
//   if (!isLoggedIn.value) return;

//   try {
//     if (hasAlert.value) {
//       await alertStore.removeAlert(props.item.id);
//     } else {
//       await alertStore.addAlert(props.item.id);
//       showAlertMessage.value = true;
//       setTimeout(() => {
//         showAlertMessage.value = false;
//       }, 2000);
//     }
//   } catch (error) {
//     console.error("Error toggling alert:", error);
//   }
// };

const openAlertModal = () => {
  if (!isLoggedIn.value) return;
  showAlertModal.value = true;
};

const closeAlertModal = () => {
  showAlertModal.value = false;
};

const addAlert = async (alertData) => {
  try {
    console.log("Adding alert with data:", alertData);
    await alertStore.addAlert(alertData);
    showAlertMessage.value = true;
    setTimeout(() => {
      showAlertMessage.value = false;
    }, 2000);
    closeAlertModal();
  } catch (error) {
    console.error("Error adding alert:", error);
    // Handle error (e.g., show error message to user)
  }
};

const removeAlert = async (alertId) => {
  try {
    console.log("Removing alert with ID:", alertId);
    await alertStore.removeAlert(alertId);
    closeAlertModal();
    // Optionally, refresh the alerts list or update the UI
  } catch (error) {
    console.error("Error removing alert:", error);
    // Handle error (e.g., show error message to user)
  }
};

// Access the pinia stores we'll need in this component
const coinsStore = useCoinsStore();
const collectionStore = useCollectionStore();

const tokenDetails = computed(() => {
  // Ensure props.item.tokens is an array of objects as expected.
  if (!Array.isArray(props.item.tokens)) {
    console.error("item.tokens is not an array", props.item.tokens);
    return [];
  }

  // Map each token object to its corresponding data from the coins store using its symbol.
  const details = props.item.tokens
    .map((tokenObj) => {
      // Extract the symbol from the token object.
      const { symbol } = tokenObj;

      // Find the matching token data in the coins store by symbol.
      const tokenData = coinsStore.coins.find((c) => c.symbol === symbol);

      // If matching token data is found, structure it for the table display.
      if (tokenData) {
        return {
          name: tokenData.name,
          priceUSD: formatNumber(tokenData.currentPriceCoinGecko),
          marketCap: formatNumber(tokenData.marketCap),
          volume24h: formatNumber(tokenData.volume24h),
          percentChange24h: formatPercentChange(tokenData.change24h),
          high24h: formatNumber(tokenData.high24h),
          low24h: formatNumber(tokenData.low24h),
        };
      } else {
        return undefined;
      }
    })
    .filter(Boolean); // Filter out any undefined entries if token data was not found.

  return details;
});

// Helper function to format numbers safely
function formatNumber(value) {
  const number = parseFloat(value);
  if (isNaN(number)) {
    return "N/A";
  }
  return number;
}

// Helper function to format percent change safely
function formatPercentChange(value) {
  const number = parseFloat(value);
  if (isNaN(number)) {
    return "N/A";
  }
  return `${number.toFixed(2)}%`;
}

// Format Date Started
const formatDateStarted = (dateString) => {
  const date = new Date(dateString);
  return date.toLocaleString("default", { month: "long", year: "numeric" });
};

let noChartAvailable = [
  "ABR",
  "HXRO",
  "SCS",
  "FOXY",
  "LFNTY",
  "FISHY",
  "ALL",
  "HSOL",
];
let tvScriptLoadingPromise;

onMounted(async () => {
  await collectionStore.fetchCollections();
  if (props.item.tokens && props.item.tokens.length > 0) {
    if (props.item.tokens[0].symbol) {
      if (!tvScriptLoadingPromise) {
        tvScriptLoadingPromise = new Promise((resolve) => {
          const script = document.createElement("script");
          script.id = "tradingview-widget-loading-script";
          script.src = "https://s3.tradingview.com/tv.js";
          script.type = "text/javascript";
          script.onload = resolve;

          document.head.appendChild(script);
        });
      }

      tvScriptLoadingPromise.then(() =>
        createWidget(props.item.tokens[0].symbol)
      );
    }
  }

  // Fetch user favorites and alerts when component mounts
  if (isLoggedIn.value) {
    if (favorites.value.length === 0) {
      favoriteStore.getUserFavorites();
    }
    if (alerts.value.length === 0) {
      alertStore.getUserAlerts();
    }
  }
});

function createWidget(symbol) {
  let tvSymbol = `CRYPTO:${symbol}USD`;
  let usdtTradingPairs = [
    "SHDW",
    "MPLX",
    "GENE",
    "CROWN",
    "NEON",
    "GUAC",
    "NANA",
    "CHONKY",
    "NOS",
    "KMNO",
    "W",
    "MOTHER",
    "POPCAT",
    "ORE",
  ];
  let usdTradingPairs = [
    "ORCA",
    "MNDE",
    "STEP",
    "JUP",
    "WEN",
    "HONEY",
    "BLZE",
    "DRIFT",
    "ZEX",
    "ZEUS",
    "INF",
  ];
  if (usdtTradingPairs.includes(symbol)) {
    tvSymbol = `${symbol}USDT`;
  } else if (usdTradingPairs.includes(symbol)) {
    tvSymbol = `${symbol}USD`;
  }

  if (document.getElementById("tradingview_42642") && "TradingView" in window) {
    new window.TradingView.widget({
      width: "100%",
      height: "420",
      symbol: tvSymbol,
      interval: "D",
      timezone: "Etc/UTC",
      theme: "dark",
      style: "1",
      locale: "en",
      enable_publishing: false,
      save_image: false,
      container_id: "tradingview_42642",
    });
  }
}

const projectCollections = computed(() => {
  // Access the SOL price from the coins store
  const solCoin = coinsStore.coins.find((coin) => coin.symbol === "SOL");
  const solPrice = solCoin ? solCoin.currentPriceCoinGecko : 1; // Fallback to 1 if not found

  return props.item.marketCapNFTs
    .map((symbol) => {
      const collection = collectionStore.collections.find(
        (c) => c.symbol === symbol
      );
      if (!collection) return null; // Or handle missing collection differently

      const marketCap =
        collection.floorPrice * collection.totalSupply * solPrice;
      return {
        name: collection.name,
        floorPrice: collection.floorPrice.toFixed(2), // Assuming floorPrice is in lamports
        avgPrice24hr: collection.avgPrice24hr.toFixed(2),
        marketCap: marketCap.toFixed(2),
        totalVolume: collection.volumeAll.toFixed(2),
        totalSupply: collection.totalSupply.toFixed(0), // Assuming you might not need decimals
        listedCount: collection.listedCount.toFixed(0),
        uniqueHolders: collection.uniqueHolders.toFixed(0),
      };
    })
    .filter(Boolean); // Remove any null entries if collection was not found
});

const formattedMarketCap = computed(() => {
  return formatBigNumber(props.item.calculatedMarketCap);
});

function formatTokenPrice(price) {
  if (!price) return;
  if (price >= 1) {
    // Price is $1 or more; format as a regular dollar amount with two decimal places
    return price.toFixed(2);
  } else if (price >= 0.01) {
    // Price is between $0.01 and $1; format with two decimal places
    return price.toFixed(2);
  } else {
    // Price is less than $0.01; format to show at least two non-zero digits
    const significantDigits = Math.ceil(-Math.log10(price) + 1); // Calculate significant digits needed
    const digitsToShow = Math.max(2, significantDigits); // Ensure at least two significant digits are shown
    return price.toFixed(digitsToShow);
  }
}

const formatBigNumber = (value) => {
  if (value < 1e3) {
    // Less than a thousand
    return value.toFixed(2); // Keeping two decimal points for values less than 1000
  } else if (value >= 1e3 && value < 1e6) {
    // Thousands
    return (value / 1e3).toFixed(2) + "K";
  } else if (value >= 1e6 && value < 1e9) {
    // Millions
    return (value / 1e6).toFixed(2) + "M";
  } else if (value >= 1e9 && value < 1e12) {
    // Billions
    return (value / 1e9).toFixed(2) + "B";
  } else if (value >= 1e12) {
    // Trillions
    return (value / 1e12).toFixed(2) + "T";
  }
  return "N/A"; // Fallback for unexpected values
};
</script>

<style scoped>
/* Existing styles */
h3,
h4 {
  font-size: 1rem;
  text-transform: uppercase;
  letter-spacing: 0.4px;
}
.price-chart {
  color: white !important;
}

.cg-widget a {
  color: white !important;
}

.cg-container {
  color: white !important;
}

.price-chart,
.price-chart * {
  color: white !important;
}

/* New styles for favorite feature */
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

button svg {
  transition: transform 0.2s ease-in-out;
}

button:hover svg {
  transform: scale(1.1);
}
</style>
