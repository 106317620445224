//functions.js

export const matchesSearch = (item, searchQuery) => {
  const query = searchQuery.toLowerCase();
  const tokenSymbols = item.tokens.map((t) => t.symbol.toLowerCase());
  const keyPeopleNames = item.keyPeople.map((p) => p.name.toLowerCase());
  const keywords = item.keywords.map((k) => k.toLowerCase());

  return (
    item.title.toLowerCase().includes(query) ||
    tokenSymbols.some((symbol) => symbol.includes(query)) ||
    keyPeopleNames.some((name) => name.includes(query)) ||
    item.category.toLowerCase().includes(query) ||
    item.website.toLowerCase().includes(query) ||
    item.twitter.toLowerCase().includes(query) ||
    keywords.some((keyword) => keyword.includes(query))
  );
};

export const matchesSearchBlog = (item, searchQuery) => {
  const query = searchQuery.toLowerCase();
  //const categories = item.Categories.map((c) => c.toLowerCase());
  const keywords = item.Keywords.map((k) => k.toLowerCase());

  return (
    item.Title.toLowerCase().includes(query) ||
    //item.Categories.toLowerCase().includes(query) ||
    keywords.some((keyword) => keyword.includes(query))
  );
};

export const initializeFromQuery = (
  query,
  directoryStore,
  activeSortOption,
  gridColumnCount
) => {
  if (query.sort) {
    activeSortOption.value = query.sort;
  }

  if (query.categories) {
    const selectedCategories = query.categories.split(",");
    directoryStore.activeCategories.clear();
    selectedCategories.forEach((category) => {
      if (directoryStore.categories.includes(category)) {
        directoryStore.activeCategories.add(category);
      }
    });
  }

  if (query.columns) {
    gridColumnCount.value = parseInt(query.columns, 10);
  }
};

export const initializeFromQueryBlog = (
  query,
  blogStore,
  activeSortOption,
  selectedKnowledgeLevel
) => {
  if (query.sort) {
    activeSortOption.value = query.sort;
  }

  if (query.categories) {
    const selectedCategories = query.categories.split(",");
    blogStore.activeCategories.clear();
    selectedCategories.forEach((category) => {
      if (blogStore.categories.includes(category)) {
        blogStore.activeCategories.add(category);
      }
    });
  }

  if (selectedKnowledgeLevel === 4) {
    //dummy code for linter, remove this log
    console.log(selectedKnowledgeLevel);
  }
};

export const updateQueryParameters = (
  router,
  route,
  activeCategories,
  gridColumnCount
) => {
  const selectedCategories = Array.from(activeCategories).join(",");
  router.push({
    query: {
      ...route.query,
      categories: selectedCategories,
      columns: gridColumnCount,
    },
  });
};

export const updateQueryParametersBlog = (
  router,
  route,
  activeCategories,
  selectedKnowledgeLevel
) => {
  const selectedCategories = Array.from(activeCategories).join(",");
  router.push({
    query: {
      ...route.query,
      categories: selectedCategories,
      knowledgeLevel: selectedKnowledgeLevel,
    },
  });
};
