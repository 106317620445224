<template>
  <div class="p-6 bg-base-200 text-base-content rounded-lg max-w-md mx-auto">
    <h2 class="text-2xl font-bold text-center text-base-content mt-6 mb-6">
      Select Wallet Type
    </h2>
    <p class="text-base-content text-center mb-6">
      This app uses the Solana blockchain for digital asset management and
      micropayments.
    </p>
    <div class="space-y-4">
      <div
        v-for="option in visibleOptions"
        :key="option.value"
        class="cursor-pointer w-full py-2 px-4 border-2 border-neutral bg-base-100 rounded"
        :class="{
          'border-2 border-primary-focus': selectedType === option.value,
          'opacity-50 cursor-not-allowed': disabledOptions.includes(
            option.value
          ),
        }"
        @click="selectOption(option.value)"
      >
        <h3 class="text-4 font-semibold mt-1 text-base-content">
          {{ option.label }}
        </h3>
        <p class="text-sm text-base-content mt-2 mb-0">
          {{ option.description }}
        </p>
      </div>
    </div>
    <div class="mt-4 mb-4 flex items-center">
      <input
        type="checkbox"
        id="advancedOptions"
        v-model="showAdvancedOptions"
        class="mr-2 custom-checkbox"
        autocomplete="off"
      />
      <label for="advancedOptions" class="text-base-content"
        >Advanced Options</label
      >
    </div>
    <div v-if="showInputField" class="mt-4">
      <input
        v-model="inputValue"
        :type="inputType"
        :placeholder="inputPlaceholder"
        class="w-full p-2 border border-neutral bg-base-100 rounded"
        autocomplete="off"
      />
    </div>
    <div
      v-if="
        selectedType === '' ||
        selectedType === 'managed' ||
        selectedType === 'manual_connect' ||
        selectedType === 'view_only'
      "
      class="h-10"
    ></div>
    <div v-if="selectedType === 'manual_connect' && !isSolanaUser">
      <div class="h-2"></div>
      <wallet-multi-button
        v-if="!connected || error"
        ref="walletButtonRef"
        dark
      ></wallet-multi-button>
      <div class="h-4"></div>
      <button
        v-if="connected && !messageSigned && !error"
        @click="signWalletMessage"
        class="w-full px-4 py-2 mb-2 bg-primary text-base-content rounded hover:bg-primary-focus transition duration-300"
      >
        Sign Message
      </button>
      <button
        v-if="showResendButton && !error"
        @click="signWalletMessage"
        class="w-full px-4 py-2 bg-neutral-focus text-base-content rounded hover:bg-base-300"
      >
        Resend Message
      </button>
    </div>

    <button
      v-if="
        selectedType !== 'manual_connect' ||
        (selectedType === 'manual_connect' && isSolanaUser)
      "
      @click="handleContinue"
      :class="[
        'w-full my-4 px-4 py-2 rounded transition duration-300',
        buttonClass,
      ]"
      :disabled="!isValid"
    >
      {{ buttonText }}
    </button>
    <p v-if="error" class="mt-4 text-center text-red-500">{{ error }}</p>
  </div>
</template>

<script>
import { ref, computed, watch, onMounted } from "vue";
import { useWallet, WalletMultiButton } from "solana-wallets-vue";
import { Keypair } from "@solana/web3.js";
import * as bip39 from "bip39";
import bs58 from "bs58";
import CryptoJS from "crypto-js";
// import { encode as base58Encode } from "bs58";

export default {
  props: {
    isSolanaUser: {
      type: Boolean,
      default: false,
    },
    existingPublicKey: {
      type: String,
      default: "",
    },
  },
  components: {
    WalletMultiButton,
  },
  setup(props, { emit }) {
    const { publicKey, connected, signMessage, disconnect } = useWallet();
    const selectedType = ref("manual_connect");
    const inputValue = ref("");
    const error = ref("");
    const messageSigned = ref(false);
    const showAdvancedOptions = ref(false);
    const showResendButton = ref(false);

    const disabledOptions = ref(["managed", "imported"]);

    const walletOptions = [
      {
        value: "view_only",
        label: "No Wallet",
        description:
          "Browse the site without a wallet. Features requiring Solana are unavailable, but you can link a wallet later in your profile.",
      },
      {
        value: "manual_connect",
        label: "Manual Connect",
        description:
          "Connect your Solana wallet to access all features. You’ll sign transactions yourself, ensuring autonomy with our tools.",
      },
      {
        value: "managed",
        label: "Managed",
        description:
          "We manage your wallet so you can enjoy the site without worrying about key management or blockchain details.",
      },
      {
        value: "imported",
        label: "Imported",
        description:
          "Import your wallet using a private key or seed phrase. Use your preferred wallet while accessing our platform’s features.",
      },
    ];

    const visibleOptions = computed(() =>
      walletOptions.filter(
        (option) => option.value !== "imported" || showAdvancedOptions.value
      )
    );

    const showInputField = computed(
      () => selectedType.value === "imported"
      //selectedType.value === "view_only" || selectedType.value === "imported"
    );

    const inputType = computed(() =>
      selectedType.value === "imported" ? "password" : "text"
    );

    const inputPlaceholder = computed(() =>
      selectedType.value === "view_only"
        ? "Enter wallet address to view"
        : "Enter private key to import"
    );

    const isValid = computed(() => {
      if (selectedType.value === "imported") {
        return inputValue.value.trim() !== "";
      }
      return selectedType.value !== "";
    });

    const selectOption = (value) => {
      if (!disabledOptions.value.includes(value)) {
        selectedType.value = value;
      }
    };

    const signWalletMessage = async () => {
      showResendButton.value = false;
      error.value = "";
      console.log("Starting message signing...");
      const messageToSign = new TextEncoder().encode(
        "Login by verifying ownership of this wallet. There is no network fee for this action."
      );

      const resendTimeout = setTimeout(() => {
        if (connected.value) {
          showResendButton.value = true;
          console.log("Show resend button");
        }
      }, 10000); // 10 seconds

      // Check if the wallet's public key already exists in the database
      const baseUrl = process.env.VUE_APP_BACKEND_API_URL;
      const checkPublicKeyResponse = await fetch(
        `${baseUrl}/users/check-public-key`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ publicKey: publicKey.value.toString() }),
        }
      );

      if (checkPublicKeyResponse.status === 409) {
        error.value =
          "This wallet is already in use. Please select another wallet.";
        await disconnect();
        return;
      }

      try {
        const signedMessage = await signMessage.value(messageToSign);
        clearTimeout(resendTimeout);

        const data = {
          walletType: "manual_connect",
          publicKey: publicKey.value.toString(),
          signature: btoa(String.fromCharCode.apply(null, signedMessage)),
        };

        messageSigned.value = true;
        emit("wallet-type-selected", data);
      } catch (error) {
        error.value = `Failed to sign message: ${error.message}`;
        console.error("Error signing message:", error.message);
        if (
          error.message.includes("Approval Denied") ||
          error.message.includes("Plugin Closed")
        ) {
          error.value = "";
          showResendButton.value = true;
        } else if (
          error.message.includes("signMessage.value is not a function")
        ) {
          showResendButton.value = false;
          error.value = "Please connect a wallet.";
        }
      }
    };

    watch(connected, (newConnected) => {
      if (newConnected && selectedType.value === "manual_connect") {
        signWalletMessage();
      }
    });

    const buttonClass = computed(() => {
      if (!selectedType.value) {
        return "bg-neutral-focus text-base-content hover:bg-base-100 cursor-not-allowed";
      }
      if (selectedType.value === "imported" && !inputValue.value.trim()) {
        return "bg-neutral-focus text-base-content hover:bg-base-100";
      }
      return "bg-primary text-base-content hover:bg-primary-focus";
    });

    const buttonText = computed(() => {
      if (!selectedType.value) {
        return "Please select an option";
      }
      // if (selectedType.value === "view_only" && !inputValue.value.trim()) {
      //   return "Please enter a wallet address";
      // }
      if (selectedType.value === "imported" && !inputValue.value.trim()) {
        return "Please import your wallet";
      }
      return "Continue";
    });

    const validatePrivateKeyOrMnemonic = (input) => {
      if (bip39.validateMnemonic(input)) {
        return true;
      }

      try {
        Keypair.fromSecretKey(Uint8Array.from(JSON.parse(input)));
        return true;
      } catch (e) {
        console.error("Invalid private key:", e);
      }

      try {
        const decoded = bs58.decode(input);
        if (decoded.length === 64) {
          return true;
        }
      } catch (e) {
        console.error("Invalid base58 encoded key:", e);
      }

      return false;
    };

    const encryptPrivateKey = (privateKey) => {
      const secretKey = process.env.VUE_APP_ENCRYPTION_KEY;
      if (!secretKey) {
        console.error("Encryption key is not set");
        throw new Error("Encryption key is not set");
      }
      return CryptoJS.AES.encrypt(privateKey, secretKey).toString();
    };

    const handleContinue = async () => {
      error.value = "";
      let data = { walletType: selectedType.value };

      switch (selectedType.value) {
        case "view_only":
          // No input required for view_only now
          break;
        case "manual_connect":
          if (props.isSolanaUser && props.existingPublicKey) {
            data.publicKey = props.existingPublicKey;
          } else if (!connected.value) {
            error.value = "Please connect a wallet first";
            return;
          }
          break;
        case "imported":
          if (!validatePrivateKeyOrMnemonic(inputValue.value)) {
            error.value = "Invalid private key or mnemonic";
            return;
          }
          data.importedWallet = encryptPrivateKey(inputValue.value);
          break;
      }

      if (
        selectedType.value !== "manual_connect" ||
        (props.isSolanaUser && props.existingPublicKey)
      ) {
        emit("wallet-type-selected", data);
      }
    };

    onMounted(() => {
      // Set default selection to manual_connect
      selectedType.value = "manual_connect";
    });

    return {
      selectedType,
      showAdvancedOptions,
      visibleOptions,
      inputValue,
      showInputField,
      inputType,
      inputPlaceholder,
      isValid,
      error,
      handleContinue,
      connected,
      signWalletMessage,
      messageSigned,
      buttonClass,
      buttonText,
      disabledOptions,
      selectOption,
    };
  },
};
</script>

<style scoped>
.custom-checkbox {
  appearance: none;
  -webkit-appearance: none;
  width: 1.25rem;
  height: 1.25rem;
  border: 2px solid theme("colors.neutral");
  border-radius: 0.25rem;
  outline: none;
  cursor: pointer;
  position: relative;
}

.custom-checkbox:checked {
  background-color: theme("colors.primary");
  border-color: theme("colors.primary");
}

.custom-checkbox:checked::after {
  content: "\2714";
  font-size: 1rem;
  color: theme("colors.base-content");
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/* Add styles for disabled options */
.opacity-50 {
  opacity: 0.5;
}

.cursor-not-allowed {
  cursor: not-allowed;
}
</style>
