import { defineStore } from "pinia";
import axios from "axios";
import siteConfig from "@/siteConfig.json";
import { usePreloadStore } from "@/stores/preloadStore";

export const useDirectoryStore = defineStore("directory", {
  state: () => ({
    items: [],
    categories: [],
    isLoading: false,
    error: null,
  }),
  getters: {
    // Add getters if needed
  },
  actions: {
    initializeStore() {
      // Initialize categories from siteConfig
      this.categories = siteConfig.directory.categories || [];
      // Initialize activeCategories with all categories
      this.activeCategories = new Set(this.categories);
    },
    async fetchProjects() {
      const preloadStore = usePreloadStore();
      if (preloadStore.preloadedData.projects) {
        this.items = preloadStore.preloadedData.projects;
        return;
      }
      const maxRetries = 5; // Maximum number of retry attempts
      const retryDelay = 300; // Delay between retries in milliseconds

      // Helper function to delay execution
      const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

      // Only fetch if items are not already loaded
      if (this.items.length === 0) {
        // Reset state before fetching
        this.isLoading = true;
        this.error = null;

        for (let attempt = 1; attempt <= maxRetries; attempt++) {
          try {
            // console.log(`Fetching ecosystem projects... (Attempt ${attempt})`);

            const response = await axios.get(
              `${process.env.VUE_APP_BACKEND_API_URL}/projects`,
              {
                headers: { "X-API-KEY": process.env.VUE_APP_X_API_KEY },
              }
            );

            // Update state with fetched data
            this.items = response.data;
            this.isLoading = false;
            // console.log(`Successfully fetched ${this.items.length} projects`);
            return; // Exit the function if successful
          } catch (error) {
            console.error(
              `Error fetching projects (Attempt ${attempt}):`,
              error
            );

            if (
              error.response &&
              error.response.status === 503 &&
              attempt < maxRetries
            ) {
              // If it's a 503 error and not the last attempt, wait before retrying
              // console.log(`Retrying in ${retryDelay / 1000} seconds...`);
              await delay(retryDelay);
            } else {
              // If it's not a 503 error or it's the last attempt, set the error state
              this.error =
                error.message || "An error occurred while fetching projects";
              this.isLoading = false;
              break; // Exit the retry loop
            }
          }
        }

        // If we've exhausted all retries, log a final error message
        if (this.error) {
          console.error("Failed to fetch projects after multiple attempts");
        }
      }
      // else {
      //   console.log("Projects already loaded, skipping fetch");
      // }
    },
    // toggleCategory(category) {
    //   if (this.activeCategories.has(category)) {
    //     this.activeCategories.delete(category);
    //   } else {
    //     this.activeCategories.add(category);
    //   }
    // },
    setActiveCategories(categories) {
      if (categories === "All") {
        this.activeCategories = new Set(this.categories);
      } else if (Array.isArray(categories)) {
        this.activeCategories = new Set(categories);
      } else {
        this.activeCategories = new Set([categories]);
      }
    },
  },
});
