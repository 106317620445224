<!-- TokenSwapPage.vue | @/pages/TokenSwapPage.vue -->
<script setup>
import { ref, onMounted, computed, watch } from "vue";
import TokenSwap from "@/components/swap/TokenSwap";
import { useHead } from "@vueuse/head";
import { useCoinsStore } from "@/stores/coins";
import { allCuratedTokens } from "@/config/allTokens.js";
import { useSiteDataStore } from "@/stores/siteDataStore";
import { findPageBySlug } from "@/utilities/general/findPageBySlug";
import { createPageMetadata } from "@/utilities/general/createPageMetadata";

const allowedTokensForSecondDropdown = allCuratedTokens;
const coinsStore = useCoinsStore();
const siteDataStore = useSiteDataStore();
let tokenObjectsForSecondDropdown = ref([]);
let dataLoaded = ref(false);
const pageContent = ref(null);

// Compute the swap page data
const swapPage = computed(() => {
  return findPageBySlug("swap");
});

// Watch for changes in the computed homePage
watch(
  swapPage,
  (newSwapPage) => {
    if (newSwapPage && newSwapPage.content) {
      pageContent.value = newSwapPage;
    } else {
      console.error("Home page content not found");
    }
  },
  { immediate: true }
);

const headData = createPageMetadata(pageContent, siteDataStore);

// Use the computed headData in useHead
watch(
  headData,
  (newHeadData) => {
    if (newHeadData) {
      useHead(newHeadData);
    }
  },
  { immediate: true }
);

onMounted(async () => {
  if (!siteDataStore.pagesData || siteDataStore.pagesData.length === 0) {
    await siteDataStore.fetchPagesData();
  }

  tokenObjectsForSecondDropdown.value = await coinsStore.getTokensByAddress(
    allowedTokensForSecondDropdown
  );
  dataLoaded.value = true; // Set the flag to true once data is fetched
});
</script>

<template>
  <div
    class="mx-auto py-4 px-8 w-full mt-24 md:mt-46 overflow-x-hidden"
    style="min-height: 100vh"
  >
    <div class="flex flex-col w-full">
      <div class="text-center pb-4">
        <h1 class="text-4xl lg:text-6xl text-base-content">
          {{ pageContent.content.title }}
        </h1>
        <p class="text-xl text-secondary-focus">
          {{ pageContent.content.subtitle }}
        </p>
      </div>
      <!-- <h1
        class="text-center text-5xl font-bold text-transparent bg-clip-text bg-gradient-to-tr from-[#9945FF] to-[#14F195]"
      >
        Token Transfer
      </h1> -->
      <token-swap
        v-if="dataLoaded"
        class="w-80 sm:w-100 md:w-120 mx-auto"
        :allowed-tokens="tokenObjectsForSecondDropdown"
      />
    </div>
  </div>
</template>

<style scoped>
h3 {
  font-size: 1.2rem;
  font-family: Lato-Regular;
}
</style>
