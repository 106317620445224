// Utility function to parse markdown links into HTML anchor tags
export const parseMarkdownLinks = (text) => {
  if (typeof text !== "string") return "";
  const linkRegex = /\[([^\]]+)\]\(([^)]+)\)(\*)?/g;
  return text.replace(linkRegex, (match, linkText, url, isExternal) => {
    if (isExternal) {
      return `<a class="text-secondary-focus hover:text-primary-focus" href="${url}" target="_blank" rel="noopener nofollow">${linkText}</a>`;
    } else {
      return `<a class="text-secondary-focus hover:text-primary-focus" href="${url}">${linkText}</a>`;
    }
  });
};

// Function to handle smooth scrolling to sections
export const scrollToSection = (event, id) => {
  event.preventDefault();
  const sectionElement = document.getElementById(id);
  if (sectionElement) {
    window.scrollTo({
      top: sectionElement.offsetTop - 120,
      behavior: "smooth",
    });
  }
};
