import { VersionedTransaction } from "@solana/web3.js";

export const buildTransaction = async (
  quoteResponse,
  userPublicKey,
  wrapAndUnwrapSol = true
) => {
  try {
    const response = await fetch("https://quote-api.jup.ag/v6/swap", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        quoteResponse,
        userPublicKey: userPublicKey.toString(),
        wrapAndUnwrapSol,
      }),
    });

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    const responseBody = await response.json();
    const { swapTransaction } = responseBody;

    // Deserialize the transaction
    const swapTransactionBuffer = new Uint8Array(
      atob(swapTransaction)
        .split("")
        .map((char) => char.charCodeAt(0))
    );
    const transaction = VersionedTransaction.deserialize(swapTransactionBuffer);
    return transaction;
  } catch (error) {
    console.error("Error in buildTransaction:", error);
    throw error; // Re-throw the error for the caller to handle
  }
};
