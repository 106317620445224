// Adjusts the amount for token decimals
function adjustAmountForDecimals(amount, decimals) {
  return amount * Math.pow(10, decimals);
}

// Function to fetch a quote from the Jupiter API
export const getQuote = async (amount, token1Info, token2Info) => {
  try {
    // console.log("~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~");
    // console.log("Getting quote!");
    // console.log("Amount:", amount);
    // console.log("Token1Info:", token1Info);
    // console.log("Token2Info:", token2Info);
    // console.log("~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~");
    // Adjust the amount for token decimals
    const adjustedAmount = Math.floor(
      adjustAmountForDecimals(amount, token1Info.decimals)
    );

    // Prepare the URL for the 'quote' API call
    const quoteApiUrl = new URL("https://quote-api.jup.ag/v6/quote");
    quoteApiUrl.searchParams.append("inputMint", token1Info.address);
    quoteApiUrl.searchParams.append("outputMint", token2Info.address);
    quoteApiUrl.searchParams.append("amount", adjustedAmount.toString());
    quoteApiUrl.searchParams.append("slippageBps", "30");

    //console.log(quoteApiUrl.toString());

    // Call the 'quote' API
    const quoteResponse = await fetch(quoteApiUrl.toString());
    if (!quoteResponse.ok) {
      throw new Error(`Error fetching quote: ${quoteResponse.statusText}`);
    }
    return await quoteResponse.json();
  } catch (error) {
    console.error("Error in getQuote:", error);
    return null; // or handle the error as needed
  }
};
