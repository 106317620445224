// @/utilities/general/usePageData.js
import { ref, computed, watch, onMounted } from "vue";
import { useSiteDataStore } from "@/stores/siteDataStore";
import { findPageBySlug } from "@/utilities/general/findPageBySlug";
import { createPageMetadata } from "@/utilities/general/createPageMetadata";

export function usePageData(pageSlug) {
  const siteDataStore = useSiteDataStore();
  const pageContent = ref(null);

  const headData = createPageMetadata(pageContent, siteDataStore);

  const pageData = computed(() => {
    return findPageBySlug(pageSlug);
  });

  watch(
    pageData,
    (newPage) => {
      if (newPage && newPage.content) {
        pageContent.value = newPage;
        // console.log(`Page content loaded for slug: ${pageSlug}`, newPage);
      } else {
        console.error("Page content not found for slug:", pageSlug);
      }
    },
    { immediate: true }
  );

  onMounted(async () => {
    if (!siteDataStore.pagesData || siteDataStore.pagesData.length === 0) {
      await siteDataStore.fetchPagesData();
      // console.log("Fetched pages data");
    }
  });

  return {
    pageContent,
    headData,
  };
}
