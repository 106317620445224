// src/utilities/general/renderContent.js
import { parseMarkdownLinks } from "@/utilities/blog/blogFunctions";
import { h } from "vue";

export function renderContent(content) {
  if (!content || !Array.isArray(content)) {
    console.error("Invalid content provided to renderContent");
    return [];
  }

  let h2Counter = 0;

  return content
    .map((contentItem) => {
      const element = contentItem.element;
      const contentText = contentItem.content;

      if (!element) {
        console.error("Element is missing in content item", contentItem);
        return null;
      }

      if (element === "img") {
        return h("img", {
          src: contentText,
          alt: contentItem.altText || "",
          class:
            "w-full h-auto object-cover object-center rounded-lg body-image",
        });
      } else if (element === "ul" || element === "ol") {
        if (Array.isArray(contentText)) {
          return h(
            element,
            { class: "dynamic-content" },
            contentText.map((item) =>
              h("li", { innerHTML: parseMarkdownLinks(item) })
            )
          );
        } else {
          console.error("List content is not an array:", contentText);
          return null;
        }
      } else {
        const props = {
          innerHTML:
            typeof contentText === "string"
              ? parseMarkdownLinks(contentText)
              : "",
          class: "dynamic-content",
        };
        if (element === "h2") {
          props.id = `section-${h2Counter++}`;
        }
        return h(element, props);
      }
    })
    .filter(Boolean);
}
