<template>
  <div>
    <!-- Overlay -->
    <div
      v-if="isOpen"
      class="fixed inset-0 bg-base-200 bg-opacity-80 z-50 transition-opacity duration-300"
      @click="closeMenu"
    ></div>

    <!-- Menu -->
    <div
      :class="[
        'fixed top-0 right-0 h-full w-64 bg-base-100 text-base-content z-50 transform transition-transform duration-300 ease-in-out',
        isOpen ? 'translate-x-0' : 'translate-x-full',
      ]"
    >
      <!-- Close button -->
      <button @click="closeMenu" class="absolute top-4 right-4 text-gray-600">
        <font-awesome-icon :icon="['fas', 'times']" class="text-2xl" />
      </button>

      <!-- Logo -->
      <router-link to="/" class="shrink-0" @click="closeMenu">
        <img :src="largeLogoSrc" :alt="largeLogoAlt" class="w-36 px-3 mt-5" />
      </router-link>

      <!-- Menu items -->
      <nav class="pt-16">
        <ul>
          <li
            v-for="item in mobileNavItems"
            :key="item.name"
            class="border-b border-base-content mx-4 pl-0 py-2"
          >
            <router-link
              :to="item.url"
              class="flex items-center px-2 py-2 text-base-content hover:text-secondary-focus hover:bg-neutral-focus hover:rounded-lg"
              @click="closeMenu"
            >
              <font-awesome-icon
                :icon="['fas', item.icon]"
                class="mr-3 w-5 h-5"
              />
              {{ item.name }}
            </router-link>
          </li>
        </ul>
      </nav>
    </div>
  </div>
</template>

<script setup>
import { computed } from "vue";
import { useSessionStore } from "@/stores/session";
import siteConfig from "@/siteConfig.json";

const sessionStore = useSessionStore();

defineProps({
  isOpen: Boolean,
});

const emit = defineEmits(["close"]);

const mobileNavItems = computed(() => siteConfig.mobileNav || []);

const closeMenu = () => {
  emit("close");
};

// Computed properties for logo source and alt text
const largeLogoSrc = computed(() => {
  const theme = sessionStore.theme === "customlight" ? "light" : "dark";
  return siteConfig[theme]?.logos?.large?.path || "";
});

const largeLogoAlt = computed(() => {
  const theme = sessionStore.theme === "customlight" ? "light" : "dark";
  return siteConfig[theme]?.logos?.large?.alt || "";
});
</script>
