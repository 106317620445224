import { defineStore } from "pinia";
import axios from "axios";

const API_BASE_URL =
  process.env.VUE_APP_API_BASE_URL || "http://localhost:8080";

export const useAlertStore = defineStore("alert", {
  state: () => ({
    alerts: [],
    error: null,
  }),
  actions: {
    async getUserAlerts() {
      try {
        const response = await axios.get(`${API_BASE_URL}/users/alerts`, {
          withCredentials: true,
        });
        this.alerts = response.data.alerts || [];
      } catch (error) {
        console.error("Error fetching alerts:", error);
        this.error = "Failed to fetch alerts";
        this.alerts = [];
      }
    },
    async addAlert(alertData) {
      try {
        // Ensure ProjectID is a number
        const payload = {
          ...alertData,
          ProjectID: Number(alertData.ProjectID),
          Price: Number(alertData.Price),
        };
        console.log("Sending alert data:", payload); // Log the payload

        const response = await axios.post(
          `${API_BASE_URL}/users/alerts`,
          payload,
          { withCredentials: true }
        );
        if (response.status === 200) {
          const newAlert = { ...payload, alertId: response.data.alertId };
          this.alerts.push(newAlert);
        }
        this.error = null;
      } catch (error) {
        console.error("Error adding alert:", error);
        if (error.response && error.response.status === 403) {
          this.error = "You've reached the maximum limit of 100 alerts.";
        } else {
          this.error = "An error occurred while adding the alert.";
        }
        throw error;
      }
    },
    async removeAlert(alertId) {
      try {
        console.log("Sending request to remove alert with ID:", alertId);
        const response = await axios.delete(
          `${API_BASE_URL}/users/alerts/${alertId}`,
          {
            withCredentials: true,
          }
        );
        if (response.status === 200) {
          this.alerts = this.alerts.filter(
            (alert) => alert.alertId !== alertId
          );
        }
        this.error = null;
      } catch (error) {
        console.error("Error removing alert:", error);
        this.error = "An error occurred while removing the alert.";
        throw error;
      }
    },
    async editAlert(editedAlert) {
      try {
        // Ensure projectId and price are numbers
        const payload = {
          ...editedAlert,
          projectId: Number(editedAlert.projectId),
          price: Number(editedAlert.price),
        };
        console.log("Sending edited alert data:", payload);

        const response = await axios.post(
          `${API_BASE_URL}/users/alerts/edit`,
          payload,
          {
            withCredentials: true,
          }
        );

        if (response.data.message === "Alert updated successfully") {
          const index = this.alerts.findIndex(
            (alert) => alert.alertId === editedAlert.alertId
          );
          if (index !== -1) {
            this.alerts[index] = payload;
          }
          this.error = null;
        } else {
          throw new Error("Unexpected response from server");
        }
      } catch (error) {
        console.error("Error editing alert:", error);
        this.error = "An error occurred while editing the alert.";
        throw error;
      }
    },
  },
  getters: {
    hasAlert: (state) => (projectId) => {
      return (
        Array.isArray(state.alerts) &&
        state.alerts.some((alert) => alert.projectId === projectId)
      );
    },
    getAlertsByProject: (state) => (projectId) => {
      return Array.isArray(state.alerts)
        ? state.alerts.filter((alert) => alert.projectId === projectId)
        : [];
    },
  },
});
