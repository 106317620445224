<template>
  <div
    class="p-8 bg-base-200 text-base-content rounded-lg shadow-lg max-w-md mx-auto"
  >
    <h2 class="text-2xl font-bold text-center text-base-content mb-6">
      Complete Your Info
    </h2>
    <form @submit.prevent="submitInfo">
      <div v-if="showEmailField" class="mb-4">
        <label for="email" class="block text-base-content mb-2">
          Email {{ requireBoth ? "" : "(optional)" }}
        </label>
        <input
          type="email"
          id="email"
          v-model="userEmail"
          class="w-full p-2 border border-neutral bg-base-100 rounded"
          placeholder="Enter your email"
        />
      </div>
      <div class="mb-4">
        <label for="name" class="block text-base-content mb-2">
          Name {{ requireBoth ? "" : "(optional)" }}
        </label>
        <input
          type="text"
          id="name"
          v-model="userName"
          class="w-full p-2 border border-neutral bg-base-100 rounded"
          placeholder="Enter your name"
        />
      </div>
      <button type="submit" :class="buttonClass" :disabled="isSubmitting">
        {{ buttonText }}
      </button>
    </form>
    <p
      v-if="message"
      class="mt-4 text-center"
      :class="{ 'text-green-500': !error, 'text-red-500': error }"
    >
      {{ message }}
    </p>
  </div>
</template>

<script>
import { ref, computed } from "vue";

export default {
  props: {
    email: {
      type: String,
      default: "",
    },
    name: {
      type: String,
      default: "",
    },
    requireBoth: {
      type: Boolean,
      default: false,
    },
    showEmailField: {
      type: Boolean,
      default: true,
    },
  },
  emits: ["info-completed"],
  setup(props, { emit }) {
    const userEmail = ref(props.email);
    const userName = ref(props.name);
    const message = ref("");
    const error = ref(false);
    const isSubmitting = ref(false);

    console.log("props", props);

    const hasInput = computed(() => {
      return (
        (props.showEmailField && userEmail.value.trim() !== "") ||
        userName.value.trim() !== ""
      );
    });

    const buttonText = computed(() => {
      return isSubmitting.value
        ? "Processing..."
        : hasInput.value
        ? "Continue"
        : "Skip";
    });

    const buttonClass = computed(() => {
      const baseClass = "w-full px-4 py-2 rounded transition duration-300 ";
      return hasInput.value
        ? baseClass + "mb-2 bg-primary text-base-content hover:bg-primary-focus"
        : baseClass + "bg-neutral-focus text-base-content hover:bg-base-100";
    });

    const submitInfo = async () => {
      isSubmitting.value = true;
      message.value = "";
      error.value = false;

      try {
        const infoToSubmit = {
          skipped: !hasInput.value,
        };

        if (!infoToSubmit.skipped) {
          if (userName.value.trim() !== "") {
            infoToSubmit.name = userName.value.trim();
          }
          if (props.showEmailField && userEmail.value.trim() !== "") {
            infoToSubmit.email = userEmail.value.trim();
          }
        }

        console.log("infoToSubmit", infoToSubmit);

        const result = await emit("info-completed", infoToSubmit);

        if (result && result.error) {
          message.value = result.error;
          error.value = true;
        } else if (result && result.success) {
          message.value = "Information updated successfully!";
          error.value = false;
        }
      } catch (err) {
        message.value = err.message;
        error.value = true;
      } finally {
        isSubmitting.value = false;
      }
    };

    return {
      userEmail,
      userName,
      message,
      error,
      submitInfo,
      isSubmitting,
      buttonText,
      buttonClass,
    };
  },
};
</script>
