import { defineStore } from "pinia";
import { useSiteDataStore } from "./siteDataStore";
import { useBlogStore } from "./blogStore";
import { useLearnStore } from "./learnStore";
import { useDirectoryStore } from "./directoryStore";
import { useCollectionStore } from "./collections";

export const usePreloadStore = defineStore("preload", {
  state: () => ({
    isPreloading: false,
    preloadedData: {},
  }),
  actions: {
    async preloadAllData() {
      if (this.isPreloading) return;
      this.isPreloading = true;

      const siteDataStore = useSiteDataStore();
      const blogStore = useBlogStore();
      const learnStore = useLearnStore();
      const directoryStore = useDirectoryStore();
      const collectionStore = useCollectionStore();

      try {
        await Promise.all([
          siteDataStore.fetchSiteData(),
          siteDataStore.fetchPagesData(),
          blogStore.fetchBlogPosts(),
          learnStore.fetchCourses(),
          directoryStore.fetchProjects(),
          collectionStore.fetchCollections(),
        ]);

        this.preloadedData = {
          siteData: siteDataStore.siteData,
          pagesData: siteDataStore.pagesData,
          blogPosts: blogStore.blogPosts,
          courses: learnStore.courses,
          projects: directoryStore.items,
          collections: collectionStore.collections,
        };
      } catch (error) {
        console.error("Error preloading data:", error);
      } finally {
        this.isPreloading = false;
      }
    },
    cacheData() {
      localStorage.setItem("preloadedData", JSON.stringify(this.preloadedData));
    },
    loadCachedData() {
      const cachedData = localStorage.getItem("preloadedData");
      if (cachedData) {
        this.preloadedData = JSON.parse(cachedData);
        return true;
      }
      return false;
    },
  },
});
