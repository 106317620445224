import { reactive } from "vue";

const eventBus = reactive({
  events: {},
  on(event, callback) {
    // console.log(`EventBus: Subscribed to event ${event}`);
    if (!this.events[event]) {
      this.events[event] = [];
    }
    this.events[event].push(callback);
  },
  off(event, callback) {
    // console.log(`EventBus: Unsubscribed from event ${event}`);
    if (!this.events[event]) return;
    this.events[event] = this.events[event].filter((cb) => cb !== callback);
  },
  emit(event, data) {
    // console.log(`EventBus: Emitting event ${event} with data:`, data);
    if (!this.events[event]) return;
    this.events[event].forEach((callback) => callback(data));
  },
});

export default eventBus;
