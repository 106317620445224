<template>
  <div
    class="p-8 bg-base-200 text-base-content rounded-lg shadow-lg max-w-2xl mx-auto"
  >
    <h2 class="text-4xl font-bold text-center mt-6 mb-6">Settings</h2>
    <div v-if="isLoading" class="text-center text-base-content">
      <p>Loading...</p>
    </div>
    <div v-else-if="user" class="space-y-6">
      <!-- Theme Selection -->
      <div v-if="siteConfig.settingsOptions.themeSelection" class="space-y-2">
        <label for="theme" class="text-lg font-semibold mt-6"
          >Theme Selection</label
        >
        <div class="relative">
          <select
            id="theme"
            v-model="session.theme"
            @change="updateTheme"
            :class="[
              'w-full p-2 border border-neutral bg-base-100 rounded appearance-none',
              { 'border-green-500': showSaved.theme },
            ]"
          >
            <option v-for="theme in themes" :key="theme" :value="theme">
              {{ theme }}
            </option>
          </select>
          <div
            class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-base-content"
          >
            <svg
              class="fill-current h-4 w-4"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
            >
              <path
                d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
              />
            </svg>
          </div>
        </div>
        <p v-if="showSaved.theme" class="text-sm text-green-500">Saved</p>
        <div v-if="!showSaved.theme" class="h-6"></div>
      </div>

      <!-- Base Currency Dropdown -->
      <div v-if="siteConfig.settingsOptions.baseCurrency" class="space-y-2">
        <label for="baseCurrency" class="text-lg font-semibold"
          >Base Currency</label
        >
        <div class="relative">
          <select
            id="baseCurrency"
            v-model="user.baseCurrency"
            @change="updateField('baseCurrency')"
            :class="[
              'w-full p-2 border border-neutral bg-base-100 rounded appearance-none',
              { 'border-green-500': showSaved.baseCurrency },
            ]"
          >
            <option
              v-for="currency in currencies"
              :key="currency"
              :value="currency"
            >
              {{ currency }}
            </option>
          </select>
          <div
            class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-base-content"
          >
            <svg
              class="fill-current h-4 w-4"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
            >
              <path
                d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
              />
            </svg>
          </div>
        </div>
        <p v-if="showSaved.baseCurrency" class="text-sm text-green-500">
          Saved
        </p>
        <div v-if="!showSaved.baseCurrency" class="h-6"></div>
      </div>

      <!-- Private RPC Input -->
      <div v-if="siteConfig.settingsOptions.rpcEndpoint" class="space-y-2">
        <label for="rpcUrl" class="text-lg font-semibold"
          >Private RPC Endpoint</label
        >
        <p class="text-sm text-base-content">
          Please disable any IP address or domain whitelists for your RPC.
        </p>
        <input
          id="rpcUrl"
          v-model="user.rpcUrl"
          :placeholder="user.rpcUrl || 'Enter your private RPC endpoint'"
          @blur="updateField('rpcUrl')"
          :class="[
            'w-full p-2 border border-neutral bg-base-100 rounded',
            { 'border-green-500': showSaved.rpcUrl },
          ]"
          autocomplete="off"
        />
        <p v-if="showSaved.rpcUrl" class="text-sm text-green-500">Saved</p>
        <div v-if="!showSaved.rpcUrl" class="h-6"></div>
      </div>
    </div>
    <div v-else class="text-center text-base-content">
      <p>You are not logged in.</p>
      <button
        @click="login"
        class="mt-4 px-8 py-2 bg-neutral-focus text-base-content rounded hover:bg-base-100 transition duration-300"
      >
        <router-link :to="'/login'"> Login </router-link>
      </button>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from "vue";
import axios from "axios";
import eventBus from "@/eventBus";
import { useSessionStore } from "@/stores/session";
import siteConfig from "@/siteConfig.json";

const session = useSessionStore();
const user = ref(null);
const isLoading = ref(true);
const showSaved = ref({
  rpcUrl: false,
  theme: false,
  baseCurrency: false,
});
const initialValues = ref({});

const themes = ["customdark", "customlight"];
const currencies = [
  "USD",
  "EUR",
  "JPY",
  "GBP",
  "AUD",
  "CAD",
  "CHF",
  "CNY",
  "HKD",
  "INR",
  "NZD",
  "SGD",
  "KRW",
  "SEK",
  "NOK",
  "MXN",
  "ZAR",
  "BRL",
  "RUB",
  "TRY",
  "BTC",
  "ETH",
  "SOL",
];

const fetchUser = async () => {
  try {
    console.log("Fetching user data...");
    const response = await axios.get(
      `${process.env.VUE_APP_BACKEND_API_URL}/auth/user`,
      { withCredentials: true }
    );
    console.log("User data:", response.data);
    user.value = response.data;
    initialValues.value = JSON.parse(JSON.stringify(user.value));

    // Update the user in the session store
    session.setUser(response.data);

    // Set the theme in the session store if it exists in user data
    if (user.value.theme) {
      session.setTheme(user.value.theme);
    }

    // Set default base currency if not set
    if (!user.value.baseCurrency) {
      user.value.baseCurrency = "USD";
    }
  } catch (error) {
    console.error("Error fetching user:", error);
    user.value = null;
  } finally {
    isLoading.value = false;
  }
};

const updateField = async (field) => {
  if (!user.value[field]) {
    user.value[field] = "";
  }
  if (user.value[field] !== initialValues.value[field]) {
    try {
      await axios.post(
        `${process.env.VUE_APP_BACKEND_API_URL}/users/update-info`,
        { [field]: user.value[field] },
        { withCredentials: true }
      );
      console.log(`${field} updated successfully`);
      showSaved.value[field] = true;
      initialValues.value[field] = user.value[field];
      setTimeout(() => {
        showSaved.value[field] = false;
      }, 2000);
    } catch (error) {
      console.error(`Error updating ${field}:`, error);
      user.value[field] = initialValues.value[field];
    }
  } else {
    console.log(`${field} unchanged, no update needed`);
  }
};

const updateTheme = async () => {
  try {
    await axios.post(
      `${process.env.VUE_APP_BACKEND_API_URL}/users/update-info`,
      { theme: session.theme },
      { withCredentials: true }
    );
    showSaved.value.theme = true;
    user.value.theme = session.theme;
    initialValues.value.theme = session.theme;
    setTimeout(() => {
      showSaved.value.theme = false;
    }, 2000);
  } catch (error) {
    console.error(`Error updating theme:`, error);
    session.setTheme(user.value.theme); // Revert the theme in the store if update fails
  }
};

const handleLogin = () => {
  console.log("Login event received");
  fetchUser();
};

const login = () => {
  eventBus.emit("showLogin");
};

onMounted(() => {
  if (session.user) {
    fetchUser();
  }
  eventBus.on("login", handleLogin);
});
</script>
