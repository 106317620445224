// router.js | @/router.js
import routes from "./routes";
import { createRouter, createWebHistory } from "vue-router";
import { useAuthStore } from "./stores/auth";

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { top: 0 };
    }
  },
});

router.beforeEach(async (to, from, next) => {
  const authStore = useAuthStore();
  const baseUrl = process.env.VUE_APP_BACKEND_API_URL;

  // Log navigation
  // console.log("Navigating to:", to.fullPath);

  // Add a check for Solana wallet login
  if (to.matched.some((record) => record.meta.requiresWallet)) {
    if (await authStore.getCurrentWallet()) {
      next();
    } else {
      alert("You must be logged in with Solana Wallet to see this page");
      next("/");
    }
  } else if (to.matched.some((record) => record.meta.requiresAuth)) {
    // Check user authentication
    try {
      // console.log("Checking user authentication...");
      const response = await fetch(`${baseUrl}/auth/user`, {
        credentials: "include",
      });
      if (response.ok) {
        const user = await response.json();
        // console.log("User authenticated:", user);
        authStore.setCurrentUser(user);
        next();
      } else {
        // console.log(
        //   "User not authenticated. Response status:",
        //   response.status
        // );
        alert("You must be logged in to see this page");
        next("/");
      }
    } catch (error) {
      // console.error("Error fetching user data:", error);
      alert(
        "An error occurred while checking authentication. Please try again."
      );
      next("/");
    }
  } else {
    next();
  }
});

export default router;
